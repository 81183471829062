
/** Creates a Vuex Module to manage and Store reports  */

import Vue from "vue"; 


const cookieSettings = {
    state: ()=>({
        allowAllCookies: true, 
        age: Date.now(), 
    }), 
    getters: {
          /** Report Plugins */
        allowAllCookies:function(state){return state.allowAllCookies} 
    }, 
    mutations: {
        accept(state,  value) {
            state.age = Date.now();
            Vue.set(state, "allowAllCookies", value);
          },
    }
}

export default cookieSettings; 
