


const settings = {
    "id": 0,
    "logo": "logo.png",
    "logo_sm": "favicon_0.png",
    "dark_mode": 0,
    "theme": {
        "highlight": "#efefef",
        "accentColor": "danger", 
        "backColor": "gradient-dark",
        "textColor": "dark"
    }
}



export default {
    settings, 
}