
/** A Helper Module to fetch Current Alerts Status from the Server  */

import Vue from "vue"
import { pressureThreshold } from "@/data/appData";
const DEFAULT_RANGE_MS = 3600*1000; 
const DEFAULT_EXCEEDED_RANGE = pressureThreshold;  
const instance = new Vue({
    name: "HydrovacAlerting", 
    data(){
        return{
            rangeMS:DEFAULT_RANGE_MS, // Default Range 
            database: "cleanharbors_lonestar",
            warningRange: 1500, // 1500 PSI 
            exceededRange: DEFAULT_EXCEEDED_RANGE, // Rule goes here 
        }
    }, 
    computed:{

    }, 
    methods:{
        
        loadEquation(item) {
            let pressureSensor = item.sensors[0];
            let equation = this.$equationMGR.getEquation(pressureSensor.equation);
      
            return equation;
          },
          encodeTimestamp(time, unit = "ms") {
            if (typeof time === "string") {
              return time;
            } else if (time instanceof Date) {
              return time.getTime().toString() + unit;
            } else {
              return time.toString() + unit;
            }
          },
          async loadPoints(item, start, end) {
            try {
              const db = this.database; 
              if (!end) end = Date.now();
              if (!start) start = new Date(end - this.rangeMS);
              let device = item.device;
              const equation = this.loadEquation(item);
      
              let query = `SELECT MEAN("hose") AS "pressure" FROM "Sensors" `;
              query += ` WHERE ("device"='${device}')  `;
              query += ` AND time>${this.encodeTimestamp(start)} `;
              query += ` AND time<${this.encodeTimestamp(end)} `;
              query += ` GROUP BY time(30s) `;
              query += ` fill(0) `;
              // query += `time>${start}ms AND time<${end}ms `;
      
              let results = await this.$influx.query(query, { database: db });
      
              let pressure = 0.0;
              let max = 0.0;
              let res = {
                points: undefined,
                max: undefined,
                pressure: undefined
              };
              if (results && results.length > 0) {
                for (let point of results) {
                  let p = this.$equationMGR.executeEquation(
                    equation.equation_id,
                    point.pressure
                  );
                  point.pressure = p;
                  if (p > max) {
                    max = p;
                  }
                  pressure += p;
                }
      
                pressure = pressure / results.length;
                if (Number.isNaN(pressure)) {
                  pressure = 0;
                }
                
              }
      
              res.points = results;
              res.max = max;
              res.pressure = pressure;
              return res;
            } catch (err) {
              console.log(err);
            }
          },
          async getPoints(item) {
            try {
              let results = await this.loadPoints(item);
              console.log(results);
              item.points = results.points;
              item.max = results.max;
              item.pressure = results.pressure;
      
              console.log(item);
            } catch (err) {
              console.log(err);
            }
          },
        async getAlarmStatus(item) {
            try {
              const db = this.database;
              let end = Date.now();
              let start = new Date(end - this.rangeMS);
              let device = item.device;
              const table = "Events";
      
              //SELECT "name", "message", "device" FROM "Rules" WHERE ("device" ='$TruckID') AND (("name"='waterOn' AND "message"=~/ Idle State/ ) OR ("name"='WandIdleChanged' AND "message"=~/Water On/ )) AND $timeFilter
              let query = `SELECT  "name", "message", "device" FROM "${table}" `;
              query += ` WHERE ("device"='${device}')  `;
              query += ` AND (("name"='waterOn' AND "message"=~/ Idle State/ ) OR ("name"='WandIdleChanged' AND "message"=~/Water On/ )) `;
              query += ` AND time>${this.encodeTimestamp(start)} `;
              query += ` AND time<${this.encodeTimestamp(end)} `;
              query += ` ORDER BY DESC `; // Sort from last to first
      
              // query += `time>${start}ms AND time<${end}ms `;
      
              let results = await this.$influx.query(query, { database: db });
      
              // If Count >0, then Alarming
              if (results && results.length > 0) {
                item.status = "alarming";
                item.timestamp = results[0].time;
                item.message = "Wand is Being Bypassed";
      
                // TODO: Check if pressure was eceeded in the last
                let start = item.timestamp.getTime() - 60 * 1000;
                let end = item.timestamp.getTime() + 60 * 1000;
                let points = await this.loadPoints(item, start, end);
                console.log(points);
                const warning = this.warningRange; 
                const exceeded = this.exceededRange; 
                if (points && points.max < warning) {
                  item.status = "warning";
                  item.message = "Wand is Being Bypassed when Washing out";
                }
      
                //item.message = results[0].message;
                console.log(item.timestamp);
              } else if (item.max > DEFAULT_EXCEEDED_RANGE) {
                item.status = "alarming";
                item.message = `Pressure Exceeded ${DEFAULT_EXCEEDED_RANGE} PSI`;
              } else {
                item.timestamp = undefined;
                item.message = "";
              }
              //
              //console.log(item);
            } catch (err) {
              console.log(err);
            }
        }
    }, 
    
});




/** Export the installation methods and get instance (singleton) */
export default {
    install(Vue, options) {
      Vue.prototype.$alerting = instance;
    },
    getInstance(){return instance}
  };
