



/** Equation Data Model here */
export class Equation {
    constructor(data){
        if(!data){
            data= {};
        }
        this.id = data.id;  // Table Reference ID
        this.equation_id = data.equation_id;// Unique ID of equation
        this.name = data.name;  // Short name for equation
        this.description = data.description; 
        this.sql = data.sql; // The SQL equivalent of the equation
        this.script = data.script; // the Javascript equivalent 
        this.inverse = data.inverse ;// Optional. if the function requires an inversion, you can id it here
        this._fields = data.fields; // Colleciton of fields used in the equation
        this.units = data.units; // What Units to display 
        this.unit_type = data.unit_type; //What category to units belong to? ie: Pressure, volume, Voltage etc. 
        
        if(this.script){
            this.fn = createFunction("obj",this.script); 
        }
    }
    
    get fields(){
        return this._fields.split(","); 
    }
    
}

export function createFunction(varName, script){
    let fn = new Function(varName, script);
    return fn; 
}


