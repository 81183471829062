/* eslint-disable vue/no-reserved-keys */


/** Async Vue Wrapper  around our API to GET and POST Data to our Back-end Server 
 * 
 * Note that A Vue Wrapper allows us to integrate it with Vue HTML Markup
 * 
*/

import Vue from "vue";
import axios from "axios";

// TODO: Load from Server Config 
let HOST_URL = "https://poc.aarcomm.io";
let API_URL = "/api"
let API_VERSION = "/v1"

const ON_CREATED_CALLBACK = () => {
}

let apiInstance;

const DEFAULT_TIMEOUT_MS = 10000; // Default Timeout  

/** Create the API Option */
const APIClient = ({
    ...options
}) => {

    if (apiInstance)
        return apiInstance;
    apiInstance = new Vue({
        data() {
            return {
                config: {},
                apiURL: "",
                _token: "",
                url: "",
                // TODO: Put your Variables Here if you need em 
                response: "",
                // Some Info about the user of the API 
                user: {
                    organizationId: "1", // The Org ID associated with the API 
                    userId: "", // The User 
                    role: "", // The users role
                    permissions: [],// Good to know our Permissions. These will be sent to us from the server 
                }, // The User we're attempting to access the API As

            }

        },
        computed: {
            /** Wrapper around the auth Method to get Bearer Token */
            apiToken: function () {
                //let token=this.$auth.getTokenSilently(); // Should be cached securely
                //console.log(token);
                if (this._token)
                    return this._token;
                else {
                    return this._token;
                }
            },
            defaultParams() {
                return {
                    "orgId": this.user.organizationId,  // TODO: Get users org ID 
                }
            },
            defaultHeaders: function () {
                return {
                    Authorization: `Bearer ${this.apiToken}`
                }
            },
            defaultTimeout() {
                if (this.timeout) {
                    return this.timeout;
                }
                else return DEFAULT_TIMEOUT_MS;
            }
        },
        methods: {
            checkPermissions() {
                console.log(this.$auth);
            },
            encodeParams(params) {
                if (!params) {
                    return this.defaultParams;
                }
                else {
                    if (!params.orgId) {
                        params.orgId = this.user.organizationId; // TODO: Use the users organization id. 
                    }
                }
                return { ...params };
            },
            /** Helper Functions */
            logError(err) {
                console.log(err);
            },

            /** Gets the Bearer Token for an authenticated user */
            async getToken() {

                if (!this.$auth.isAuthenticated) {
                    // TODO: Detect if the user is Authenticated first 
                    // If not, Log them in
                }
                if (!this._token)
                    this._token = await this.$auth.getTokenSilently();

                return this._token;
            },

            async create(url,body,params, options) {
                let res;
                try {
                    this.checkPermissions();
                    await this.getToken();
                    res = await axios.post(this.url + url,body, {
                        headers: { ...this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    }
                    );
                    this.response = res;
                } catch (err) {
                    this.logError(err);
                }
                return res;
            },

            async get(url, params, options) {
                let res;
                try {
                    this.checkPermissions();
                    await this.getToken();
                    res = await axios.get(this.url + url, {
                        headers: { ...this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    }
                    );
                    this.response = res;
                } catch (err) {
                    this.logError(err);
                }

                return res; 


            },

            async update(url,body,params, options) {
                let res;
                try {
                    this.checkPermissions();
                    await this.getToken();
                    res = await axios.put(this.url + url,body, {
                        headers: { ...this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    }
                    );
                    this.response = res;
                } catch (err) {
                    this.logError(err);
                }
                return res;
            },

            async delete(url, params, options) {
                let res;
                try {
                    this.checkPermissions();
                    await this.getToken();
                    res = await axios.delete(this.url + url, {
                        headers: { ...this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    }
                    );
                    this.response = res;
                } catch (err) {
                    this.logError(err);
                }
                return res;
            },




            async signOn(body, params) {
                let ret;
                try {
                    this.response = axios.post(this.url + '/sign-on?', body, {
                        params: this.encodeParams(params),
                        headers: {
                            ...this.defaultHeaders,
                            "Content-Type": "application/json"
                        },
                        timeout: this.defaultTimeout,
                    })
                } catch (err) {
                    this.logError(err);
                }

                return this.response;

            },

            async singleSignOn(url, params) {
                try {
                    // TODO: When we Sign-In, we should sign into all via our backend using login method

                    // Then as a Hack, we should sign-on here, and redirect to our App 
                    let url = "https://poc.aarcomm.io/app/grafana/login/generic_oauth";
                    //let response = axios
                } catch (err) {
                    this.logError(err);
                }

                return this.response;
            }


        },
        created() {
            // TODO: Emit event indicating API Services is ready

            // Try Configure the Settings from the user's configuration 
            this.config = { ...options };  // 
            let host = options.apiHost;
            if (!host)
                host = HOST_URL; // Fallback to Default 

            this.apiURL = options.apiEndpoint;
            if (!this.apiURL)
                this.apiURL = API_URL; // Falls back to default. if apiEnd point not specified. 
            let path = this.apiURL + this.config.apiVersion;
            this.url = new URL(path, host);


            console.log(this.url.pathname);

            //this.url = new URL(this.apiURL, temp);
            axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
            ON_CREATED_CALLBACK();
        }

    });

    return apiInstance;
}


export default APIClient;











