

/** Some temporal constants here to help with time */
const MS_PER_SEC = 1000; 
const MS_PER_MINUTE = 60*MS_PER_SEC; 
const MS_PER_HOUR =60*MS_PER_MINUTE; 
const MS_PER_DAY = 24 *MS_PER_HOUR; 


export default {
    MS_PER_SEC, 
    MS_PER_MINUTE,
    MS_PER_HOUR,
    MS_PER_DAY
}