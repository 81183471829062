

import {Equation,createFunction} from "@/lib/equationProcessing"; 
import Vue from "vue"


// Define the equation Manager object here
/**
 * We declared it with instance so we could convert it to a singleton down the road. 
 */

const instance = new Vue({

    data(){
        return {
            equations:{
                // Map of equation objects 
            }
        }
    }, 
    methods:{
        init(eqnList){
            try{
                for(let item of eqnList){
                    this.addEquation(item.equation_id,item); 
                }
            }
            catch(err){
                console.log(err); 
            }
        }, 
        createFunction(name,script){
            return createFunction(name,script); 
        }, 
        addEquation(id, data){
            let eq; 
            if(this.equations[id]){
                return; // Only Allowed if equation not already in use.  
            }
            eq = new Equation(data); 
            this.equations[id] = eq; 
        }, 
        removeEquation(id){
            delete this.equations[id]; 

        }, 
        getEquation(id){
            // do we really need a getter? 
            return this.equations[id]; 
        }, 
        executeEquation(id, argument){
            let eq = this.equations[id]; 
            if(eq){
                if(typeof(argument)==="object")
                    return eq.fn(argument); 
                else{
                    // Convert to Object and run??? 
                    let temp = {}; 
                    for(let field of eq.fields){
                        temp[field.replaceAll('"', '')] = argument; 
                    }
                    return eq.fn(temp); 
                }
            }
        }
    }
}
);


export default {
    install(Vue, options) {
      Vue.prototype.$equationMGR = instance;
    },
    getInstance(){return instance}
  };

