


const influxConfig = {
    host: 'broker.aarcomm.io',
      port: 8086,
      protocol: 'http',
       username: "aarcomm", // Leave these empty! Configure externally 
       password: "aarcomm123", 
      schema: [
      ]
  }

export default influxConfig; 