export default {
  data: function() {
    return {};
  },
  methods: {
    async getAlerts(params) {
      let res;
      try {
        res = await this.client.get("/alerts", params);
      } catch (err) {
        this.logError(err);
      }
      return res;
    },

    async getAlertByUID(uid, params) {
      let res;
      try {
        res = await this.client.get(`/alerts/${uid}`, params);
      } catch (err) {
        this.logError(err);
      }
      return res;
    },

    async createAlert(data, params) {
      let res;
      try {
        res = await this.client.create(`/alerts`, data);
      } catch (err) {
        this.logError(err);
      }
      return res;
    },

    async updateAlertByUID(uid, data, params) {
      let res;
      try {
        res = await this.client.update(`/alerts/${uid}`, data, params);
      } catch (err) {
        this.logError(err);
      }

      return res;
    },

    async deleteAlertByUID(uid, params) {
      let response;
      try {
        response = await this.client.delete(`/alerts/${uid}`, params);
      } catch (err) {
        this.logError(err);
      }

      return response;
    },

    async enableAlertByUID(uid, params) {
      let response;
      try {
        response = await this.client.update(`/alerts/${uid}/enable`, params);
      } catch (err) {
        this.logError(err);
      }
      return response;
    },

    async disableAlertByUID(uid, params) {
      let response;
      try {
        response = await this.client.update(`/alerts/${uid}/disable`, params);
      } catch (err) {
        this.logError(err);
      }
      return response;
    }
  }
};