/** Placeholder for all Common Aarcomm IoT Data Storage Needs within vuex */

import Vue from 'vue';
import defaultSettings from "../../../config/defaultSettings.js" 

/* Import Test Data for Testing 
import { weeklyfleetactivity } from "../../views/demo/demoData.js";

import { assets } from "../../views/demo/demoData.js";
import { users } from "../../views/demo/demoData.js";
*/
const MAX_AGE_MS = 10 * 5000;

const iotDataStore = {
    state: () => ({
      age: 0,
      maxAge: MAX_AGE_MS,
      summaryData: { // TODO: Provide Hooks for this to store 
        //volumeDispensedData: volumeDispensedData,
        //fuelTransactionData: fuelTransactions,
        weeklyFleetData: undefined,
        //slippage: slipplageDataSummary,
        //assetSummary: assetSummary,
        //dieselPrice: dieselPrice,
      },
      status: [],

      users: [],
      settings: defaultSettings, // TODO: Should supply default settings  

      // TODO: Should be Member of  Custom Plugin
      selectedTruck: undefined,
      selectedBranch: 0,
      //recentTransactions: [],//recentTransactions,
      assets: [],
      geofences: undefined, //geofences, 
      equations: undefined,
      polygonGroups: [],
      tempPolygons: null, 
      branches: [],
      circularGeofences: [],
      geofenceCategories: [],
      alerts: [],
      rules: [],
      deviceGroups: [],
      previewCircularGeofence: null,
      recentActivityList: [],
      weeklyActivityList: null,
      inventory: undefined, // We will use store to cache the inventory
      dashboards: {
        // TODO: Which Dashboards do we want to make available? 
        realtime: [

        ],
        history: [

        ],
      }
    }),

    actions: {
      /** None Yet  */
    },
    getters: {
      inventory: (state) => state.inventory,
      dashboards: (state) => state.dashboards,
      users: (state) => state.users, // Only if user is admin!
      selectedTruck: (state) => state.selectedTruck,
      selectedBranch: (state) => state.selectedBranch,

      activity: (state) => state.recentActivityList,
      status: (state) => state.status,
      weeklyfleetactivity: (state) => state.weeklyActivityList,
      branches: (state) => state.branches,
      settings: (state) => state.settings,
      geofences: (state) => state.geofences,
      equations: (state)=>state.equations, 
      /** Plugins  */
      assets: (state) => state.assets,
      polygonGroups: (state) => state.polygonGroups,
      tempPolygons: (state) => state.tempPolygons,
      circularGeofences: (state) => state.circularGeofences,
      geofenceCategories: (state) => state.geofenceCategories,
      alerts: (state) => state.alerts,
      deviceGroups: (state) => state.deviceGroups,
      rules: (state) => state.rules,
      previewCircularGeofence: (state) => state.previewCircularGeofence,
    },
    mutations: {

      updateData(state, [variable, value]) {
        state.age = Date.now();

        Vue.set(state, variable, value);
      },



      updateWeeklyActivity(state, data) {

        Vue.set(state, 'weeklyActivityList', data);
        //state.weeklyActivityList = data;
      },

      updateBranches(state, value) {
        state.age = Date.now();
        console.log("updateBranches", value);
        Vue.set(state, "branches", value);
      },
      selectBranch(state, branch_id) {
        if (state.branches) {
          let selected = state.branches.find(item => item.branch_id === branch_id);
          Vue.set(state, "selectedBranch", selected);
        }
      },



      updateRecentActivity(state, data) {
        try {
          //alert(data);
          state.age = Date.now();
          if (!state.recentActivityList || state.recentActivityList.length == 0) {
            state.recentActivityList = [...data];
          } else {
            for (var item of data) {
              state.recentActivityList.unshift(item);
            }


            // TODO: Limit the Number of entries here 

          }

          state.recentActivityList = state.recentActivityList.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));



        } catch (err) {
          console.log(err);
        }

        //return dataStore.recentActivityList; 
      },

      // TODO: Push the List of Dashboards available for the application 
      updateDashboards(state, data) {
        try {
          if (!data)
            return;
          const updateDashboard = (state, usrKey, dashboard) => {
            let key = usrKey.toString(); // Just incase we did something stupid and our key isn't a string!
            let dashboardList = state.dashboards[key];
            // First we check if the dashboard list exists already. 
            if (!dashboardList)
              return; //TODO:  For now, do nothing. but in the future we can make this dynamic!! 
            // Next we check if the dashboard even belongs to our Group 
            let entry = dashboardList.find(x => x.id === dashboard.id)
            // If Exists, then we just update the record by Index
            if (entry) {
              entry = dashboard; // TODO: Should we update by index here? or is updating the reference OK? 
            } else { // If Doesn't Exist, then we must create a new Record
              dashboardList.push(dashboard);
            }
          }

          // Data is an Array of Objects 
          //let keys =["hisotry", "realtime"];  // Application Specific types
          // Re-init the Data store object 
          //state.dashboards.realtime = []; 
          //state.dashboards.history = []; 
          for (var dashboard of data) {
            if (dashboard.key === "realtime" || dashboard.key_id == "realtime") {
              updateDashboard(state, "realtime", dashboard)
              //state.dashboards["realtime"].push(dashboard);
            } else if (dashboard.key === "history" || dashboard.key_id == "history") {
              updateDashboard(state, "history", dashboard)
              //state.dashboards["history"].push(dashboard);
            }
            // TODO: What if  unkown,
          }
        } catch (err) {
          console.log(err);
        }

      },
      updateDashboard(state, usrKey, dashboard) {
        try {
          let key = usrKey.toString(); // Just incase we did something stupid and our key isn't a string!
          let dashboardList = state.dashboards[key];
          // First we check if the dashboard list exists already. 
          if (!dashboardList)
            return; //TODO:  For now, do nothing. but in the future we can make this dynamic!! 


          // Next we check if the dashboard even belongs to our Group 
          let entry = dashboardList.find(x => x.id === dashboard.id)
          // If Exists, then we just update the record by Index
          if (entry) {
            entry = dashboard; // TODO: Should we update by index here? or is updating the reference OK? 
          } else { // If Doesn't Exist, then we must create a new Record
            dashboardList.push(dashboard);
          }

          // Not Necessary, but a good idea to sort ascending

        } catch (err) {
          console.log("Could not Update Dashboard. " + err);
        }

      },
      updateStatus(state, data) {
        try {
          Vue.set(state, "status", data);
        } catch (err) {
          console.log(err);
        }
      },
      updateEquations(state, equations){
        let key = "equations"; 
        Vue.set(state, key, equations); 
      },




      // Custom Data Store Handlers 
      /**
       * Updates the Symmary Elements 
       * @param {*} state 
       * @param {*} data // A Data Object containing summary data 
       */
      updateSummaryData(state, data) {

        //let keys = Object.keys(data);

        state.summaryData = {
          ...data
        };


      },


      updateAssetData(state, data) {
        let key = "assets";
    
        let temp = data.map(item => {
          return { ...item, active: Boolean(item.active) };
        });
        Vue.set(state.dataStore, key, temp);
    
        console.log(state.assets);
      },
    
      updateAsset(state, data) {
        
    
        let asset = state.assets.find(item => item.uid === data.uid);
        if (data.active != null || data.active != undefined) {
          data.active = Boolean(data.active)
        }
    
        if (asset) {
          Object.assign(asset, data);
        }
        else {
          asset = {};
          Object.assign(asset, data); // Create a deeper copy 
          state.assets.push(asset);
        }
        let index = state.assets.indexOf(asset);
        
        //let key = "assets";
        //Vue.set(state.dataStore, key, temp); 
    
        console.log(state.assets[index]);
      },

      modifyAPolygon (state, newPoly, groupId) {
        let groupIdx = state.polygonGroups.findIndex((group) => {
          return group.getId() == groupId;
        })
        let polyIdx = state.polygonGroups[groupIdx].findIndex((polygon) => {
          return polygon.getId() == newPoly.getId();
        })

        state.polygonGroups[groupIdx].splice(polyIdx, 1, newPoly);
      },

      modifyATempPolygon (state, newPoly) {
        state.tempPolygons.modifyPolygon(newPoly); //H_test_0017: test this function
      },

      uploadPolygonsFromDrawing (state, newPolys) {
        state.polygonGroups.push(newPolys);
      },

      updateTempPolygons(state, tempPolys) {
        state.tempPolygons = tempPolys;
      },

      clearTempPolygons(state) {
        state.tempPolygons = null;
      },

      setPreviewCircularGeofence(state, geofence) {
        state.previewCircularGeofence = geofence; 
      }
  }
}



export default iotDataStore;
