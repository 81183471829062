import Vue from "vue";
import createAuth0Client from "@auth0/auth0-spa-js";
import axios from "axios";
import { ADMIN_TAG, SYSADMIN_TAG} from "./roles";
import roles from "./roles";
//import { get } from "core-js/core/dict";

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};
const DEFAULT_LOGIN_CALLBACK = () => {};

const DEFAULT_CONNECTION = "aarcomm";
const DEFAULT_HOST = "demo1.aarcomm.io";
const USER_LOGO_SM_PATH = "userLogoSm";
const USER_LOGO_PATH = "userLogo";
const DEFAULT_ORG_ID = "1";
const DEFAULT_BRANCH = "1"; // TODO: Get from configuration
const NAMESPACE = "http://aarcomm.io"; // TODO: Environment Variable
const META_DATA_KEY = `${NAMESPACE}/app_metadata`;
const DEFAULT_USER_LOGO = "userLogoSm.jpg";
// TODO: Get from config here
const defaultTheme = {
  highlight: "#efefef",
  backColor: "gradient-dark"
};
let instance; // this is a singleton

//const REDIRECT_URI = window.location.origin;
let REDIRECT_URI = window.location.origin;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = REDIRECT_URI, // reconfigure with options as needed
  onLoggedInCallback = DEFAULT_LOGIN_CALLBACK,
  ...options
}) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
        appState: null,
        code: null,
        role: null,
        _defaultConnection: undefined,
        options: undefined,

        userData: {}
      };
    },
    computed: {
      connection() {
        if (this.userData && this.userData.connection) {
          return this.userData.connection;
        }
        return this._defaultConnection;
      },
      userLogoSm() {
        if (this.user && this.user[USER_LOGO_SM_PATH]) {
          return this.user[USER_LOGO_SM_PATH];
        }
        return DEFAULT_USER_LOGO;
      },
      userLogo() {
        // TODO: Should really use store for this!!
        if (this.user && this.user[USER_LOGO_PATH]) {
          return this.user[USER_LOGO_PATH];
        }
        return DEFAULT_USER_LOGO;
      },
      /** Splits the subscriber ID into 2 Parts and returns the UID portion */
      user_id() {
        if (this.user && this.user.sub) {
          let temp = this.user.sub.split("|")[1];
          return temp;
        }
        return "";
      },
      branch_id() {
        if (this.user && this.userData) {
          return this.userData.branch_id;
        }
        return DEFAULT_BRANCH; // Default
      },
      orgId() {
        if (this.userData) {
          return this.userData.orgId;
        }
        return DEFAULT_ORG_ID;
      },

      permissions() {
        if (this.user && this.userData) {
          return this.userData.role;
        } else {
          return "viewer"; // Default to Viewer Only
        }
      }
    },

    methods: {
      isAdmin() {
        return (
          this.role === SYSADMIN_TAG || this.role === ADMIN_TAG
        );
      },
      isVerified() {
        return this.user ? this.user.email_verified : false;
      },
      isBlocked() {
        return this.user ? this.user.blocked : false;
      },

      // Deprecated Method: remove in future versions
      async ssoLogin(args) {
        try {
          console.log("Logging into Other Apps via Single Sign-on");

          let host = DEFAULT_HOST || window.location.host;
          let protocol = window.location.protocol || "http";
          let subDir = "/api/grafana";
          let url = `${protocol}//${host}${subDir}/login/generic_oauth`;
          let params = {};
          console.log(url);
          let response = await axios.get(url, {
            params: params,
            headers: this.$api.defaultHeaders
          });

          console.log(response);
        } catch (err) {
          console.log(err);
        }
      },
      /** Authenticates the user using a popup window */
      async loginWithPopup(o, config) {
        this.popupOpen = true;

        try {
          await this.auth0Client.loginWithPopup(o, config);
          this.user = await this.auth0Client.getUser();

          this.isAuthenticated = await this.auth0Client.isAuthenticated();
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);
        }
        this.popupOpen = false;
        this.isAuthenticated = true;
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();

          this.isAuthenticated = true;
        } catch (e) {
          this.error = e;
        }
        this.loading = false;
      },

      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        if (!o) {
          o = {};
        }
        o.connection = this._defaultConnection; //DEFAULT_CONNECTION;

        // TODO: Get Default Connection
        return this.auth0Client.loginWithRedirect(o);
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },
      /** Gets the access token using a popup window */

      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        return this.auth0Client.logout(o);
      },

      parseAuth0Metadata(o) {
        // Handle Metadata
        try {
          if (this.user[META_DATA_KEY]) {
            let metadata = this.user[META_DATA_KEY];
            console.log(metadata);
            let orgId = metadata.orgId;
            if (Array.isArray(orgId)) {
              orgId = orgId[0];
            }
            this.$api.user.organizationId = orgId;
          }
        } catch (err) {
          console.log(err);
        }
      },

      async loadUserSettings(params) {
        try {
          let branch_id = this.userData.branch_id; 
          let data = await this.$app.loadSettings({
            branch_id: branch_id, 
          });

          if (data) {
            this.user[USER_LOGO_PATH] = data.logo;
            this.user[USER_LOGO_SM_PATH] = data.logo_sm;
            this.user.metadata = data.metadata;
            // TODO: Handle if the user has a theme set. Else give defaults;
            if (!data.theme) {
              this.user.theme = defaultTheme;
            } else {
              this.user.theme = data.theme; //JSON.parse(res.data.theme);
            }
            if (data.dark_mode && parseInt(data.dark_mode) > 0) {
              this.user.darkMode = true; // TODO: Get from user settings.
            } else {
              this.user.darkMode = false;
            }
          }
        } catch (err) {
          console.log(err);
        }
      },
      async getUserMetadata() {
        try {
          // Perform this step in App and use DataStore
          let res = await this.$api.getUserByID({}, this.user_id);
          if (res.status == 200) {
            console.log(res.data);
            this.userData = { ...res.data };
            this.role = this.userData.role; // TODO: role should be property of user
          }
        } catch (e) {
          this.error = e;
          console.log(e);
        }
      },

      async onLoggedIn() {
        try {
          if (onLoggedInCallback) {
            onLoggedInCallback(this.user);
            // TODO: Emit an event here
          }
        } catch (err) {
          console.log("Exception occurred while logging in. " + err);
        }
      },
      async loadMetadata() {
        let ret;
        try {
          // Get user data from auth0 
          this.user = await this.auth0Client.getUser(); 
          if (this.user) {
            // Then parse the data from auth0. Must do this first
            this.parseAuth0Metadata();

            // Now load the users meta data 
            await this.getUserMetadata();
            // Finally Load the user's application settings
            await this.loadUserSettings();
  
            console.log(this.userData);
          }
        } catch (err) {
          console.log(err);
        }
        return ret;
      },
  
      async handleLoggedIn(e) {
        try {
          await this.loadMetadata(); // Load the user metadata here
          this.onLoggedIn(); // Emit the logged in event
        } catch (err) {
          console.log(err);
        }
      },
    },

    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object

      try {
        this.loading = true; 
        this.auth0Client = await createAuth0Client({
          domain: options.domain,
          client_id: options.clientId,
          audience: options.audience,
          redirect_uri: redirectUri
        });

        this.options = { ...options }; // Save a Copy of the options internally
        this._defaultConnection = this.options.defaultConnection;
        // If the user is returning to the app after authentication..
        if (window.location.search.includes("code=") &&
          window.location.search.includes("state=")
        ) {
          // Extract the params

          let params = new URLSearchParams(window.location.search);

          let code = params.get("code");
          let state = params.get("state");
          this.appState = state;
          this.code = code;

          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback();

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState);
        }
        
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        await this.handleLoggedIn();
      } 
      catch (e) {
        console.log(e);
        this.error = e;
      }

      this.loading = false;
    }
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  }
};
