
/** A Library of helper classes and functions to help getting and displaying realtime status  */


import devicesRegistrationStatusEnum from "../iot/iotEnums"; 
import { DeviceStatus } from "../iot/models";

/** Enums to help indicate what a 
 * Truck is currently doing 
 */
const deviceStatusEnum = {
    NONE:"none", // Nothing 
    OFFLINE: "offline", // 
    STOPPED: "stopped", // Device is stopped / offline

    IDLE: "idle",  // Device is sitting idle 
    MOVIING: "moving",  // Device is on the move 
    WORKING: "working", // Device is currently operating 
    WARNING: "warning", // Device  
    ALARMING: "alarming" // Device is Alarming 
}

const alarmingEnum = {
    none: 0,
    offline: 1,
    stopped: 2,
    idle: 3,
    moving: 4,
    warning: 5,
    alarming: 6,
  };



/** These are the possible Fleet status states you can have 
 * 
 */
const fleetStatusEnum = {
    NONE: "none", 
    STOPPED: "stopped", 
    IDLE: "idle", 
    ACTIVE: "moving", 
    OPERATING: "working"
}




const connectionStatusEnum ={
    OFFLINE: "offline", 
    ONLINE: "online", 
}






class ConnectionStatus {
    device; // device id
    branch_id; // branch_id the device belongs to 
    status;  // the Actual Status of the device
    last_connect; 
    
    constructor(device, branch_id){
        this.device = device; 
        this.branch_id = branch_id; 
        this.status = connectionStatusEnum.NONE; 
    }
}

/** Helper Class for Fleet / GPS Status for Realtime Activity 
 * 
 */
class FleetStatus {
    device;
    branch_id;
    status; // What is the current fleet status . See emum
    state; // device state as 
    speed; // What is the current speed 
    gps; // What was the last known location 
    geofence; // Return an ID of a Geofence if inside
    constructor(device, branch_id, status){
        this.device = device; 
        this.branch_id = branch_id; 
        this.status = status; 
    }


}


/** Helper class to access and display Realtime Fleet status
 * 
 */

/** Rule Evaluation Status  
 * Helper class to display rules that are currently being voiladed
 * 
*/
class RuleStatus{
    message; // A Message about the rule 
    timestamp; // When was it violated
    status; // What is the current rule status; 
    rule_id; // should contain the proper rule being violdated 
    constructor(rule_id, status, timestamp, message){
        this.rule_id = rule_id; 
        this.status = status; 
        this.timestamp = timestamp; 
        this.message = message; 
    }

    // PROPERTIES 
}




/** 
 * Helper Class for Deailing with Alarm State
 */
class AlarmStatus{
    alarm_id; // the ID of the alarm 
    status; // 
    message; // The Alarm Message
    timestamp; 
    constructor(alarm_id, status, timestamp, message) {
            this.alarm_id = alarm_id; 
            this.status = status; 
            this.timestamp = timestamp; 
            this.message = message; 
    }

}



/** 
 * 
 */
class HydrovacStatus {
    id; // The table Id associated with the object
    device; // the Device ID 
    name; // Human Friendly name 
    status; // Hydrovac Status. Different than device stats!
    deviceData; // Device metadata 
    data; // An object containing data associated with device for display 
    
    
    constructor(statusMessage){
        if(statusMessage){
        this.device = statusMessage.device;
        this.name = statusMessage.name; 
       
        
        this.data= {
            points: statusMessage.points, 
            pressure: statusMessage.pressure, 
            max: statusMessage.max, 
        }
        // TODO: This portion should really be a "Rule Status"
        this.status = statusMessage.status; 
        this.timestamp = statusMessage.timestamp; 
        this.message = statusMessage.message;
    }
    }
    
    
    /** Computed Properties  */
    get points(){
        return this.data?this.data.points: undefined; 
    } 
}


export default { 
    deviceStatusEnum, 
    devicesRegistrationStatusEnum,
    fleetStatusEnum,
    alarmingEnum,
    connectionStatusEnum,
    HydrovacStatus,
    RuleStatus,
    AlarmStatus,
    DeviceStatus, 
    FleetStatus, 
    ConnectionStatus, 
    
    
}