import Vue from "vue"

const DEFAULT_PERIOD_MS = 30*1000; 
const instance = new Vue({
    name: "FleetManager", 
    data(){
        return{
            // Update Period for Fleet Status
            updatePeriod: DEFAULT_PERIOD_MS, 
            devices: undefined, // Collection of devices 
            deviceMap: undefined, // HashMap of devices 
            fleetStatus: undefined, 
            fleetStatusMap: undefined, // Hashmap of fleet status
            dataSets: {fleetActivity: undefined
            }, // A collection of Fleet Data
        }
    }, 
    computed:{

    }, 
    methods:{

        // Required Methods:

        // Load Devices / Fleet Objects
        async loadDevices(branch_id, params) {
            try {
      
              // get Available devices first from the cache 
              let devices = this.$app.myDevices;
              if (!devices) {
                // If no devices, we should load the inventory first!
                devices = await this.$app.loadInventory(params);
              }
      
              // Now that we have the entire fleet status, lets refine
      
              // Now lets filter devices by branch_id 
              devices = devices.filter(item => item.branch_id === branch_id);
              if (!devices) {
                // Raise Error Here 
                return;
              }
              this.deviceMap = this.mapFleet(devices); 
              return devices;
            } catch (err) {
              console.log(err)
            }
        }, 
        async loadDeviceInfo(item) {
            try {
              let ret;
              let device = item.device;
              let resp = await this.$api.getDeviceInfoByID(device);
              if (resp && resp.status == 200) {
                ret = resp.data;
              }
              return ret;
            } catch (e) {
              console.log(e);
            }
          },

        // Map Fleet / Devices 
        mapFleet(devices){
            let map = {}; // Create a new Hashmap 
            for(let device of devices){
                map[device.device] = device; 
            }
            return map; 

        },
        
        // Load Fleet Status 

        async loadFleetStatus(params) {
            /** Load the Current Fleet Status, filtered by active devices */
            try {
              let fleet = await this.$app.getFleetStatus(params);
              this.fleetStatus = fleet; 
              
              return fleet;
            } catch (err) {
              console.log(err)
            }
          },

          mapFleetStatus(status){
            let map = {}; // Create a new Hashmap 
            for(let device of status){
                map[device.device] = device; 
            }
            return map; 

        },

        async loadFleetActivity(params){
            try{
                this.dataSets.fleetActivity = await this.$app.getFleetActivityAsync(
                    params
                  );

                return this.dataSets.fleetActivity; 
            }
            catch(err){
                console.log(err); 
            }
        }

        // Map Fleet Status Object 

    }


}); 




/** Export the installation methods and get instance (singleton) */
export default {
    install(Vue, options) {
      Vue.prototype.$fleet = instance;
    },
    getInstance(){return instance}
  };