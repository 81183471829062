

/** Module to help Load Configs from the various configuration files  
 * 
 * This Module uses index to bundle all the configs together
*/

// Import the authentication settings
import auth_config from "./auth_config.json"; 
import auth_config_dev from "./auth_config_development.json"; 

// Import the API configuration settings
import api_config from "./api_config.json"; 
import api_config_dev from "./api_config_development.json"; 
import api_config_staging from "./api_config_staging.json"; 
import influx_config from "./influx_config"; 


export default{
    auth_config, 
    auth_config_dev : auth_config_dev, // Use the Production Auth Config
    api_config, 
    api_config_dev: api_config_dev, // Use the Production API end-point 
    api_config_staging,
    influx_config,
}