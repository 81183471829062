


/** 
 * These are the possible Device Registration States 
 */
export const devicesRegistrationStatusEnum = {
    NONE: "none", // Indicates the device has not been configured 
    READY: "ready", // Device has been configured and is ready for deployment 
    ACTIVE: "active", // Indicates that device is ready 
    DISABLED: "disabled", // Indicates that device's service is suspended
    DEACTIVATED: "deactivated" // Indicateds that device has been de-commissioned
}


