


 import 'core-js/stable'
 import Vue from 'vue'
 import CoreuiVue from '@coreui/vue'
 import App from './App'
 import router from './router/index'
 import { iconsSet as icons } from './assets/icons/icons.js'
 import config from "../config"; 
 import store from './store/store'
 import i18n from './i18n.js'
 import 'leaflet/dist/leaflet.css';
 import axios from "axios"; 
 import { Auth0Plugin } from "./plugins/auth";
 import './services/sw/registerServiceWorker';
 import { VueFriendlyIframe } from 'vue-friendly-iframe';
/** Import moment JS  */
import moment from "moment";
import { Icon } from 'leaflet';

/** Import and Init Font Awesome  for global use*/
import { library as faLibrary } from './assets/icons/faIcons.js';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { API } from './services/api';

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import {InfluxAPI} from "./services/influx"; 
import { CustomAPI } from "./services/cleanharbors-lonestar-api"
import EquationManager from "@/plugins/EquationManager"; 
import HydrovacAlerting from "@/plugins/alerting/HydrovacAlerting"; 
import HydrovacAlertingV2 from "@/plugins/alerting/HydrovacAlertingV2"; 
import FleetManager from '@/plugins/fleets/FleetManager'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';


/** Custom warn handler, to avoid vue warn showing up as (red) errors on Chrome debugger.
    this is ignored in production
*/
if (process.env.NODE_ENV == 'development') {
  Vue.config.warnHandler = function (msg) {
    console.warn("[vue-warning]:", msg);  
  }
}



/** Init your Global Components Here  */
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('vue-friendly-iframe', VueFriendlyIframe);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

 
 

/** Install your Global Services Here  */
Vue.prototype.window = window;// Access Window Object
 
/** TODO: Create a Theme Service Here */
 Vue.prototype.$theme = store.getters.theme;


/** TODO: Create a Logger Service Here */
 Vue.prototype.$log = console.log.bind(console)

 Vue.use(require('vue-moment'), { moment });
 

Vue.use(axios);
Vue.use(VueFriendlyIframe);
Vue.use(store);
Vue.use(CoreuiVue)
Vue.use(EquationManager);
//Vue.use(HydrovacAlerting); 
Vue.use(HydrovacAlertingV2); 
Vue.use(FleetManager); 
Vue.use(VueTelInput);




 /** Import our App Configuration  and configure the API */
 // Import the API Configuration 

const {api_config, api_config_dev,api_config_staging, influx_config} = config; 

 console.log(api_config_dev); 
 
 let apiHost, apiEndpoint, apiVersion, worldmap, grafana; 
 if(process.env.NODE_ENV === "production"){
   apiHost = api_config.apiHost; 
   apiEndpoint = api_config.apiEndpoint; 
   apiVersion = api_config.apiVersion; 
   worldmap = api_config.worldmap;
   grafana = api_config.grafana; 
 }
 else if(process.env.NODE_ENV ==="staging"){
  apiHost = api_config_staging.apiHost; 
  apiEndpoint = api_config_staging.apiEndpoint; 
  apiVersion = api_config_staging.apiVersion; 
  worldmap = api_config_staging.worldmap;
  grafana = api_config_staging.grafana; 
}
 else{
   apiHost = api_config_dev.apiHost; 
   apiEndpoint = api_config_dev.apiEndpoint; 
   apiVersion = api_config_dev.apiVersion; 
   worldmap = api_config_dev.worldmap;
   grafana = api_config_dev.grafana; 
 }
 
 console.log(apiHost, apiVersion, apiEndpoint, worldmap);
 
 
 /** Import the API we want to use with this app  */
 Vue.use(API, {
   apiHost,
   apiEndpoint,
   apiVersion,
   grafana,
   worldmap,
 }
 );

 Vue.use(CustomAPI, {
  apiHost,
  apiEndpoint,
  apiVersion,
}
);
 
 
 /** Auth0 Integration */

 
 // TODO: It may be better to load from the JSON file instead of env variables 
 
 let domain, clientId, audience, defaultConnection; 
 
 const {auth_config, auth_config_dev} = config; 
 
 if(process.env.NODE_ENV==="production"){
   domain = auth_config.domain; 
   clientId = auth_config.clientId; 
   audience = auth_config.audience;
   defaultConnection = auth_config.defaultConnection; 
   ({domain, clientId,audience, defaultConnection} = auth_config); 
 }
 else{
   ({domain, clientId,audience, defaultConnection} = (auth_config_dev)); 
 }
 
 console.log(domain, clientId, audience, defaultConnection);
 // Import the plugin here
 let main;
 
 /** Load the application data in the background 
  * TODO: Put into small library called app.js
 */
 
 const MainView = () => { return main.$children[0] }
 const appLoadCallback = (o) => {
  console.log("App Loaded");
   console.log(o); // Notify that we have logged in 
   console.log(main);
   // TODO: Handle if user is admin or not. Either Route to App view or not 
 
   MainView().onLoad();  // Hack to get the main app reference
   // TODO: Use a Watch instead to listen for changes on logged in and logged out. 
 }
 
 
console.log(domain, clientId, audience);
// Import the plugin here

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  defaultConnection,

  //redirectUri,
  onRedirectCallback: appState => {
    if (appState && appState.targetUrl) {
      router.push(appState.targetUrl);
    }
    else {
      window.history.replaceState({}, document.title, window.location.pathname);
      //router.push("home"); // Navigate to Home? or /{user}/app  
    }

    // Check if we are logged in. If not, then we go back to the login page 
  },
  onLoggedInCallback: appLoadCallback,
});




Vue.use(InfluxAPI, influx_config); 

 /** Work around for Webpack not rendering Icons  */
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});



main = new Vue({
  el: '#app',
  router,
  store,
  icons,
  faLibrary,
  i18n,
  template: '<App/>',
  components: {
    App
  }
})

/** Hacky bit to reference the application. Prefere to use Vue.use() */
Vue.prototype.$app = MainView(); // main;

 
 export const eventBus = new Vue(); // creating an event bus.
 
 