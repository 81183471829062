
import {devicesRegistrationStatusEnum} from "./iotEnums"; 

/**IoT Device Module. Represents data returned from API  */
export class IoTDevice{
    id; // table id. 
    device; // Device ID, required. 
    branch_id;  // required 
    sensors; // Collection of sensor;
    name; // Human Friendly name 
    description; // Device Descriptor 
    status; // Registration status; 
    imei; // Cell id 
    constructor( device, branch_id){
        this.device = device; 
        this.branch_id = branch_id; 
    }

    get truck(){
        return this.name; // alias for truck name; 
    }
    set truck(val){
        // Dummy value 
    }
}


/** 
 * Helper to Access and Display IoT Device Registaration 
 * Status 
 */
export class DeviceStatus {
    device; // device id
    branch_id; // branch_id the device belongs to 
    status;  // the Regisistration Status of the IoT device
    constructor(device, branch_id){
        this.device = device; 
        this.branch_id = branch_id; 
        this.status = devicesRegistrationStatusEnum.NONE; 
    }
}


/** Helper class for IoT users  */
export class User{
    id; 
    uid; 
    email; 
    connection; 
    role; 
    first; 
    last; 
    phone_number; 
    organization; // 
    userpool;
    connection; 
    provider; 
    team_id; 
    branch_id; 
}


/** Helper class for acceping and parsing Dashboard Data  */
export class Dashboard{
    uid; 
    device; 
    branch_id; 
    name;
    title; 
    type; 
    key_id; 
    group; 
    host; // Where is the dashboard hosted? 
    dashboard; // Dashboard Data  
    permissions; // Permissions group 
    url;
    metadata; 

    constructor(uid, name, title, device, branch_id){
        this.uid = uid;
        this.name = name;
        this.title = title; 
        this.device = device; 
        this.branch_id = branch_id; 
    }

}


/** Defines sensor Object / interface  */
export class Sensor{
    sensor_id;  // Id of the sensor being used 
    aarcomm_partno; // Aarcomm part number descriptor 
    name;  // A human friendly name for the sensor 
    description; // A Description of the sensor 
    output; // Optional description of the output 
    unit; // The Output Unit of the sensor 
    max; // The max 
    min; // the Min Range 
    metadata; // extra metadata about the sensor 
    channel; // the Channel ID the senosor is connected to on
    equation_id; // the equation ID the sensor requires. 

}

export class Jobs{

}
