export default {
  data: function() {
    return {};
  },
  methods: {
    async getRules(params) {
      let res;
      try {
        res = await this.client.get("/rules", params);
      } catch (err) {
        this.logError(err);
      }
      return res;
    },

    async getRuleByUID(uid, params) {
      let res;
      try {
        res = await this.client.get(`/rules/${uid}`, params);
      } catch (err) {
        this.logError(err);
      }
      return res;
    },

    async insertRule(data, params) {
      let res;
      try {
        res = await this.client.create(`/rules`, data);
      } catch (err) {
        this.logError(err);
      }
      return res;
    },

    async updateRuleByUID(uid, data, params) {
      let res;
      try {
        res = await this.client.update(`/rules/${uid}`, data, params);
      } catch (err) {
        this.logError(err);
      }

      return res;
    },

    async deleteRuleByUID(uid, params) {
      let response;
      try {
        response = await this.client.delete(`/rules/${uid}`, params);
      } catch (err) {
        this.logError(err);
      }

      return response;
    },

    async enableRuleByUID(uid, params) {
      let response;
      try {
        response = await this.client.update(`/rules/${uid}/enable`, params);
      } catch (err) {
        this.logError(err);
      }
      return response;
    },

    async disableRuleByUID(uid, params) {
      let response;
      try {
        response = await this.client.update(`/rules/${uid}/disable`, params);
      } catch (err) {
        this.logError(err);
      }
      return response;
    }
  }
};
