/* eslint-disable vue/no-reserved-keys */


/** Async Vue Wrapper  around our API to GET and POST Data to our Back-end Server 
 * 
 * Note that A Vue Wrapper allows us to integrate it with Vue HTML Markup
 * 
*/

import Vue from "vue";
import axios from "axios";
import APIClient from "./apiClient"; 

// Import your API Extensions here and use them as mixin
import geofences from "./extensions/geofences"; 
import geofenceCategories from './extensions/geofenceCategories';
import alerts from './extensions/alerts';
import deviceGroups from './extensions/deviceGroups';
import rules from './extensions/rules' 


// TODO: Load from Server Config 
let HOST_URL = "https://poc.aarcomm.io";
let API_URL = "/api"
let API_VERSION = "/v1"

const ON_CREATED_CALLBACK = () => {
}

let apiV1Instance;

const DEFAULT_TIMEOUT_MS = 10000; // Default Timeout  

/** Create the API Option */
export const APIv1 = ({
    ...options
}) => {

    if (apiV1Instance)
        return apiV1Instance;
    apiV1Instance = new Vue({
        data() {
            return {
                config: {},
                apiURL: "",
                _token: "",
                url: "",
                // TODO: Put your Variables Here if you need em 
                response: "",
                // Some Info about the user of the API 
                myUser: {
                    organizationId: "1", // The Org ID associated with the API 
                    userId: "", // The User 
                    role: "", // The users role
                    permissions: [],// Good to know our Permissions. These will be sent to us from the server 
                }, // The User we're attempting to access the API As
                client:undefined,
            }
            

        },
        mixins:[geofences, geofenceCategories, alerts, deviceGroups, rules], // Extend your API with mixins!
        computed: {
            user:{
                get(){
                    if(this.client) 
                        return this.client.user;
                    else 
                        return this.myUser;
                },
                set(val){
                    this.myUser=val;
                    if(this.client)
                        this.client.user = val; 
                }
            },
            /** Wrapper around the auth Method to get Bearer Token */
            apiToken: function () {
                //let token=this.$auth.getTokenSilently(); // Should be cached securely
                //console.log(token);
                if (this._token)
                    return this._token;
                else {
                    return this._token;
                }
            },
            defaultParams() {
                return {
                    "orgId": this.user.organizationId,  // TODO: Get users org ID 
                }
            },
            defaultHeaders: function () {
                return {
                    Authorization: `Bearer ${this.apiToken}`
                }
            },
            defaultTimeout() {
                if (this.timeout) {
                    return this.timeout;
                }
                else return DEFAULT_TIMEOUT_MS;
            }
        },
        methods: {
            checkPermissions() {
                console.log(this.$auth);
            },
            encodeParams(params) {
                if (!params) {
                    return this.defaultParams;
                }
                else {
                    if (!params.orgId) {
                        params.orgId = this.user.organizationId; // TODO: Use the users organization id. 
                    }
                }
                return { ...params };
            },
            /** Helper Functions */
            logError(err) {
                console.log(err);
            },

            /** Gets the Bearer Token for an authenticated user */
            async getToken() {

                if (!this.$auth.isAuthenticated) {
                    // TODO: Detect if the user is Authenticated first 
                    // If not, Log them in
                }
                if (!this._token)
                    this._token = await this.$auth.getTokenSilently();

                return this._token;
            },

            /** GET Methods */

            /** Query the Inventory for the particular org / user */
            async getInventory(params) {

                try {
                    this.response = await this.client.get("/inventory", params);

                    
                }
                catch (err) {
                    this.logError(err)
                }

                return this.response;


            },

            /** Query the Sensor (by Param).
             * Accepts: User Specified URL Params
             * Required: URL Params must specify the Sensor ID. to query 
             */
            getSensors(params) {

            },

            /** Query the Status of All Available devices 
            * Accepts
            */
            async getFleetStatus(params) {
                try {
                    await this.getToken();

                    this.response = await axios.get(this.url + "/fleet/status", {
                        headers: { ...this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    });

                } catch (err) {
                    this.logError(err);
                }

                return this.response;


            },

            /**
             * Query the Latest Fleet Activity 
             * Accepts: User Specified URL Params, 
             * Returns: The Latest Summarized Fleet Activity 
             */
            async getFleetActivity(params) {
                try {
                    await this.getToken();
                    this.response = await axios.get(this.url + "/fleet/activity", {
                        headers: { ...this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                        //params:params,
                    });
                } catch (err) {
                    this.logError(err);
                }

                return this.response;

            },


            /** Helper Function to Obtain Weekly Fleet Activity */
            async getWeeklyFleetActivity(params) {
                try {
                    await this.getToken();
                    this.response = await axios.get(this.url + "/fleet/weeklyactivity", {
                        headers: { ...this.defaultHeaders },
                        //params:params,
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    });
                } catch (err) {
                    this.logError(err);
                }

                return this.response;

            },

            /** Query the Sensor with the Specific ID
             * Accepts: User Specefied URL Params,
             * Accepts: User Must provide the ID of the object we are querying
             * Returns: the Sensor Data associated with that device as JSON
            */
            async getSensorByID(id, params) {
                try {
                    await this.getToken();
                    this.response = await axios.get(this.url + `/devices/${id}/sensors`, {
                        headers: { ...this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    });
                } catch (err) {
                    this.logError(err);
                }

                return this.response;
            },

            // TODO: Do Generic Versions of all APIs specified below 
            // getRadio()

            /** Query the Radio By ID  
             * Accepts: ID of the Radio Object to Query
             * Accepts: JSON Object of query parameters 
             * Returns: Returns the Radio Data for the specified data  
            */
            async getRadioByID(id, params) {
                try {
                    await this.getToken();
                    this.response = await axios.get(this.url + `/devices/${id}/radio`, {
                        headers: { ...this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    });
                } catch (err) {
                    this.logError(err);
                }

                return this.response;
            },

            /** Query the Device Location by ID
             * Required: ID of the device we want to query 
             * Accepts: JSON Object containing additional Query Parameters
             * Returns: An Array of Location Data for the specific device 
              */

            async getLocationByID(id, params) {
                try {
                    await this.getToken();
                    this.response = await axios.get(this.url + `/devices/${id}/location`, {
                        headers: { ...this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    });
                } catch (err) {
                    this.logError(err);
                }

                return this.response;

            },

            /** Query the Device Meta Data by ID  
             * Required: ID of the device we want to query 
             * Accepts: JSON Object containing additional query params
             * Returns: A JSON Object containing the requested meta-data
            */
            async getDeviceInfoByID(id, params) {
                try {
                    await this.getToken();

                    this.response = await axios.get(this.url + `/devices/${id}/info`, {
                        headers: { ...this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    });

                } catch (err) {
                    this.logError(err);
                }

                return this.response;

            },


            async getDeviceActivity(params) {
                try {
                    await this.getToken();

                    this.response = await axios.get(this.url + `/devices/activity`, {
                        headers: { ...this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    });
                }
                catch (err) {
                    this.logError(err)
                }

                return this.response;

            },

            /** Query the Latest Activity associated with a Device
             * Required: ID of the Device we want to query 
             * Accepts: A JSON Object containing additional query Parms
             * <br> Note these can be time range and limit 
             * Returns: An Array of JSON objects containing Activity Data. 
             */
            async getDeviceActivityByID(id, params) {
                try {

                    if (!id || id < 0) {
                        return this.getDeviceActivity(params);
                    }

                    else { // If Use didnt specify an ID, then choose device activity 
                        await this.getToken();
                        this.response = await axios.get(this.url + `/devices/${id}/activity`, {
                            headers: { ...this.defaultHeaders },
                            params: this.encodeParams(params),
                            timeout: this.defaultTimeout,
                        });
                    }


                } catch (err) {
                    this.logError(err);
                }

                return this.response;



            },

            /** Query the Status of All Available devices 
             * Accepts
             */
            async getDeviceStatus(params) {
                try {
                    await this.getToken();

                    this.response = await axios.get(this.url + "/devices/status", {
                        headers: { ...this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    });

                } catch (err) {
                    this.logError(err);
                }

                return this.response;

            },
            /** Queries the Server for Available Reports 
             * Accepts: User Defined Params as JSON Object 
             * Returns: Array of Report Objects; 
            */
            async getReports(params) {
                try {
                    await this.getToken();

                    this.response = await axios.get(this.url + "/reports", {
                        headers: { ...this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    });

                } catch (err) {
                    this.logError(err);
                }

                return this.response;

            },
            /** Queries the Server for Available Reports 
             * Accepts: User Defined Params as JSON Object 
             * Returns: Array of Report Objects; 
            */
            async getReportsByID(id, params) {
                try {
                    await this.getToken();

                    this.response = await axios.get(this.url + `/reports/${id}`, {
                        headers: { ...this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    });

                } catch (err) {
                    this.logError(err);
                }

                return this.response;

            },


            /**  Queries the Server for Jobs / Pending Jobs 
             * Accepts: User Defined Params of KVP as a JSON Object, which can be used to filter values in the query 
             * Returns: Returns an Array of Jobs that match the request 
            */
            async getJobs(params) {
                try {
                    await this.getToken();

                    this.response = await axios.get(this.url + "/jobs", {
                        headers: { ...this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    }
                    );
                } catch (err) {
                    this.logError(err);
                }

                return this.response;
            },

            /** returns Details about a Specific Job Number / ID
             * Requires: User must supply a Job ID (String: Number). See getJobs to return list of available jobs 
             * Accepts: Additional Params as KVP assed as JSON Object
             * Returns: Object that represents the Job that matches the ID 
             */
            async getJobById(id, params) {
                try {
                    await this.getToken();
                    this.response = await axios.get(this.url + "/jobs/" + id, {
                        headers: { ...this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    }
                    );
                } catch (err) {
                    this.logError(err);
                }

                return this.response;

            },

            /**
             * 
             * @param {*} params 
             * @returns Global App Settings 
             */
            async getSettings(params) {
                try {
                    await this.getToken();
                    this.response = await axios.get(this.url + "/settings", {
                        headers: { ...this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    }
                    );
                } catch (err) {
                    this.logError(err);
                }

                return this.response;
            },

            /**
           * Gets the User Info / Settings for a particular ID 
           * Requires: Supply the Unique ID String for the requested User  
           * @param {*} params 
           * @returns user Settings 
           */
            async getUsers(params) {
                let res;
                try {
                    this.checkPermissions();
                    await this.getToken();
                    res = await axios.get(this.url + "/users", {
                        headers: { ...this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    }
                    );
                    this.response = res; 
                } catch (err) {
                    this.logError(err);
                }

                return this.response;
            },

            /**
           * Gets the User Info / Settings for a particular ID 
           * Requires: Supply the Unique ID String for the requested User  
           * @param {*} params 
           * @returns user Settings 
           */
            async getUserByID(params, uid) {
                try {
                    await this.getToken();
                    this.response = await axios.get(this.url + "/users/" + uid, {
                        headers: { ...this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    }
                    );
                } catch (err) {
                    this.logError(err);
                }

                return this.response;
            },

            /** Add a User to the System 
             * Required Params: 
             *      email: 
             *      password: 
             *      first: First name
             *      last: Last name, 
             */
            async createUser(body, params) {
                let res;
                try {
                    this.checkPermissions();
                    await this.getToken();
                    // Pre-Check for valid fields 
                    res = axios.post(this.url + "/users", body, {
                        params: this.encodeParams(params),
                        headers: {
                            ...this.defaultHeaders,
                            'Content-Type': 'application/json'
                        },
                        timeout: this.defaultTimeout,

                    }
                    );
                } catch (err) {
                    this.logError(err);
                }

                return res; 
            },
            /** Updates the user at the specified ID */
            async updateUser(id, body, params) {
                let res;
                try {
                    this.checkPermissions();
                    await this.getToken();
                    // Pre-Check for valid fields 
                    res = axios.put(this.url + "/users/" + id, body, {
                        params: this.encodeParams(params),
                        headers: {
                            ...this.defaultHeaders,
                            'Content-Type': 'application/json'
                        },
                        timeout: this.defaultTimeout,
                    }
                    );
                } catch (err) {
                    this.logError(err);
                }

                return res;
            },
            /** Updates the user at the specified ID */
            async blockUser(id, body, params) {
                let res;
                try {
                    // Pre-Check for valid fields 
                    res = axios.put(this.url + "/users/" + id + "/block", body, {
                        params: this.encodeParams(params),
                        headers: {
                            ...this.defaultHeaders,
                            'Content-Type': 'application/json'
                        },
                        timeout: this.defaultTimeout,
                    }
                    );
                    this.response = res; 
                } catch (err) {
                    this.logError(err);
                }

                return this.response;
            },
            async deleteUser(id, params) {
                let res;
                try {
                    res = axios.delete(this.url + `/users/${id}`,
                        {
                            params: this.encodeParams(params),
                            headers: { ...this.defaultHeaders },
                            timeout: this.defaultTimeout,
                        }
                    );
                    this.response = res; 
                } catch (err) {
                    this.logError(err);
                }

                return this.response;
            },

            /**  */
            async suspendUser(id, body, params) {

            },

            /** Initiate the Reset Password Process */
            async resetPassword(body, params) {

                try {
                    this.response = axios.post(this.url
                        + "/users/change_password",
                        body,
                        {
                            params: this.encodeParams(params),
                            headers: { ...this.defaultHeaders },
                            timeout: this.defaultTimeout,
                        }

                    );
                } catch (err) {
                    this.logError(err);
                }

                return this.response;
            },


            /** Get / Search Available Dashboards 
             * Accepts: A key-value pair of query parameters for customized filters 
             * Returns: An Array of JSON Objects corresponding to a dashboard in the user's app  
             */
            async getDashboards(params) {
                try {
                    await this.getToken();
                    this.response = await axios.get(this.url + "/dashboards", {
                        headers: { ... this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    });
                } catch (err) {
                    this.logError(err);
                }

                return this.response;
            },

            /** Get Dashboard  By Specific ID 
             * Requires: A Valid ID that corresponds to a Dashboard 
             * Accepts: An Object containing additonal Key-value pairs as optional argument
             * Returns: A JSON encoded object containig all the details about the Particular Dashboard 
            */
            async getDashboardsById(id, params) {
                try {
                    await this.getToken();
                    this.response = await axios.get(this.url + "/dashboards/" + id, {
                        headers: { ... this.defaultHeaders },
                        params: this.encodeParams(params),
                        timeout: this.defaultTimeout,
                    });
                } catch (err) {
                    this.logError(err);
                }

                return this.response;
            },


            async getBranches(params) {
                try {
                    this.response = await this.client.get("/branches", params); 
                    
                } catch (err) {
                    this.logError(err);
                }

                return this.response;
            },

            async getEquations(params){
                let ret; 
                try{
                   await this.getToken(); 
                   ret = axios.get(this.url + '/equations', {
                       params : this.encodeParams(params),
                     headers: {...this.defaultHeaders,
                       "Content-Type": "application/json"
                    }
                   });
                }
                catch(err){
                    this.logError(err); 
                    ret = this.handleErrorResponse(err); 
                }
                return ret; 
            },


            /** Get Custom Data  */
            async getCustomAPI(endPoint, params) {
                try {
                    await this.getToken();
                    let host = this.config.apiHost;

                    let path = endPoint;
                    let url = new URL(path, host).toString();
                    console.log(url);

                    this.response = await this.client.get(url, params);



                } catch (err) {
                    this.logError(err);
                }

                return this.response;
            },



            async signOn(body, params) {

                try {
                    this.response = await this.client.singleSignOn(url, params);
                   
                } catch (err) {
                    this.logError(err);
                }

                return this.response;

            },

            async singleSignOn(url, params) {
                try {

                    this.response = await this.client.singleSignOn(url, params); 
                    
                } catch (err) {
                    this.logError(err);
                }

                return this.response;
            }




            /** POST Methods */

            /** PUT / Update Methods  */

            /** DELETE Methods */


        },
        created() {
            

            this.client = APIClient({...options}); // Get API Helper Instance 
            // Note: API CLient is a Singleton!
            
            // Try Configure the Settings from the user's configuration 


            this.config = { ...options };  // 
            

            let host = options.apiHost;
            if (!host)
                host = HOST_URL; // Fallback to Default 

            this.apiURL = options.apiEndpoint;
            if (!this.apiURL)
                this.apiURL = API_URL; // Falls back to default. if apiEnd point not specified. 
            let path = this.apiURL + this.config.apiVersion;
            this.url = new URL(path, host);


            console.log(this.url.pathname);

            //this.url = new URL(this.apiURL, temp);
            axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
           // TODO: Emit event indicating API Services is ready
            ON_CREATED_CALLBACK();
        }

    });

    return apiV1Instance;
}

/** Finally Export to Vue  as a Plug-in*/
export const API = {
    install(Vue, options) {
        Vue.prototype.$api = APIv1(options);
    }
}








