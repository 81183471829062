const fieldList = ["id", "uid", "name", "description", "created", "updated" , "devices_uids"]

class DeviceGroup {
    constructor(data) {
        for (var key of Object.keys(data)) {
            if (fieldList.includes(key)) {
                this[`_${key}`] = data[key];
            }
        }
    }

    get id () {
        return this._id;
    }

    get uid () {
        return this._uid;
    }

    get name () {
        return this._name;
    }

    get description () {
        return this._description;
    }

    get created () {
        return this._created;
    }

    get updated () {
        return this._updated;
    }

    get devices_uids () { // h_todo_lonestar_101: make the changes in the database to retrieve the correct data
        return ['device1', 'device2', 'device3']
    }
}

export { DeviceGroup }
