/** A Helper Module to fetch Current Alerts Status from the Server
 * Note: we have a few dependencies here: We need equation processor to be
 * present
 */

import Vue from "vue";
import statusHelper from "@/lib/hydrovac_lib/statusHelper";

import { rangeMS, pressureThreshold } from "@/data/appData";
const { HydrovacStatus, alarmingEnum, AlarmStatus } = statusHelper;

const DEFAULT_RANGE_MS = rangeMS ? rangeMS : 3600 * 1000 * 4;
const DEFAULT_DATABASE = "cleanharbors_lonestar"; // Default connection
const DEFAULT_ONLINE_TIMEOUT_MS = 3600 * 1000;
const DEFAULT_EXCEEDED_RANGE = pressureThreshold;

const instance = new Vue({
  name: "HydrovacAlerting",
  data() {
    return {
      // Settings
      rangeMS: DEFAULT_RANGE_MS, // Default Range
      database: DEFAULT_DATABASE, // Default database to access your data
      warningRange: 1500, // 1500 PSI
      exceededRange: DEFAULT_EXCEEDED_RANGE, // Rule goes here
      items: undefined, // the current Alert Status collection.
      fleetStatus: undefined, // Keep a reference to Fleet status? or make another plugin?
      updating: false
    };
  },
  computed: {
    alertStatus() {
      return this.items;
    }
  },
  methods: {
    getOnlineState(item, threshold = DEFAULT_ONLINE_TIMEOUT_MS) {
      let temp = "offline";
      if (item.deviceInfo.status && item.deviceInfo.status.last_connect) {
        let timestamp = item.deviceInfo.status.last_connect;
        let datetime = new Date(timestamp);

        // If Last connected within x hours then treat as online
        if (Date.now() - datetime < threshold) {
          // Update
          temp = "online";
        }
      } else if (item.deviceInfo.lastConnect) {
        temp = "online";
      }
      return temp;
    },
    sortByOnlineState(items) {
      items.sort((a, b) => {
        if (!a.deviceInfo || !b.deviceInfo) {
          return 0;
        }
        let aVal = a.connected === "online" ? 1 : 0;
        let bVal = b.connected === "online" ? 1 : 0;
        return bVal - aVal;
      });
    },
    sortByStatus(items) {
      items.sort((a, b) => {
        let aVal = alarmingEnum[a.status];
        let bVal = alarmingEnum[b.status];

        if (!aVal && !bVal) {
          return 0;
        } else if (aVal > bVal) {
          return -1;
        } else if (aVal < bVal) {
          return 1;
        } else return 0;
      });
    },
    async getFleetStatus(params) {
      /** Load the Current Fleet Status, filtered by active devices */
      try {
        let fleet = await this.$app.getFleetStatus(params);
        return fleet;
      } catch (err) {
        console.log(err);
      }
    },

    async loadDeviceInfo(item) {
      try {
        let device = item.device;
        let ret;
        let resp = await this.$api.getDeviceInfoByID(device);
        if (resp && resp.status == 200) {
          ret = resp.data;
        }
        return ret;
      } catch (e) {
        console.log(e);
      }
    },

    async loadDevices(branch_id, params) {
      try {
        // 1. List Available devices first
        let devices = this.$app.myDevices;
        if (!devices) {
          // If no devices, we should load the inventory first!
          devices = await this.$app.loadInventory(params);
        }

        // Now that we have the entire fleet status, lets refine

        // Now lets filter devices by branch_id
        devices = devices.filter(item => item.branch_id === branch_id);
        if (!devices) {
          // Raise Error Here
          return;
        }
        return devices;
      } catch (err) {
        console.log(err);
      }
    },

    clear() {
      this.items = [];
    },

    async update(branch_id) {
      try {
        this.updating = true;
        let params = {
          branch_id: branch_id
        };

        // Step 1: Determine if we have devices;
        let devices = await this.loadDevices(branch_id, params);

        // NOTE: THis is a temp fix, The Instance should not contain an Object!
        devices = devices.filter(
          item => item.status === "active" || typeof item.status === "object"
        );

        // Step 2: Get the Fleet Status.
        // TODO: This should only fetch the status for the currently active devices

        //this.fleetStatus = await this.getFleetStatus(params);

        // Create a Status collection

        //this.clear();
        let tempCollection = [];

        //  Loop through all the devices in our inventory
        for (let device of devices) {
          let myStatus = await this.getDeviceStatus(device, params);

          // Add to the collection
          tempCollection.push(myStatus);
          this.notifyStatusLoad(myStatus);
        }

        this.items = tempCollection;
      } catch (err) {
        console.log(err);
      }

      this.updating = false;

      return this.items;
    },

    async getDeviceStatus(device, params) {
      try {
        let myStatus = new HydrovacStatus();

        myStatus.status = "stopped"; // What is the default?
        myStatus.deviceInfo = device;
        myStatus.name = device.name; //
        myStatus.device = device.device; // Device ID
        // Get the current alert status
        let alertStatus = await this.getAlarmStatus(device);

        // If no Alert, then print the current fleet status

        myStatus.status = alertStatus.status;
        myStatus.timestamp = alertStatus.timestamp;
        myStatus.message = alertStatus.message;

        // Get realtime data
        let pointsData = await this.getPoints(device);

        let deviceInfo = await this.loadDeviceInfo(device);

        // Update last connected here
        myStatus.deviceInfo = deviceInfo;
        myStatus.connected = this.getOnlineState(myStatus);

        myStatus.data = pointsData;

        return myStatus;
      } catch (err) {
        console.log(err);
      }
    },
    notifyStatusLoad(myStatus) {
      this.$emit("status-load", myStatus);
    },

    loadEquation(item) {
      let pressureSensor = item.sensors[0];
      let equation = this.$equationMGR.getEquation(pressureSensor.equation);

      return equation;
    },

    async loadPoints(item, start, end) {
      try {
        const query = {
          start,
          end,
          range: this.rangeMS,
          device: item.device
        };

        let res = await this.$customAPI.getPoints(query);

        if (res) {
          let pointsMessage = res.data;
          return pointsMessage.points;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getPoints(item) {
      try {
        let results = await this.loadPoints(item);

        if (results) {
          item.data = results.points;

          //item.points = results.points;
          item.max = results.max;
          item.pressure = results.pressure;
        }

        return results;
      } catch (err) {
        console.log(err);
      }
    },

    /** Load alerts / Alarms */
    async getAlarms(item, start, end) {
      try {
        let range = end - start;

        const query = {
          device: item.device,
          range
        };
        let res = await this.$customAPI.getAlertStatus(query);
        if (res && res.status === 200) {
          let alertsCollection = [];
          for (let temp of res.data) {
            let id = undefined; // TODO:
            let timestamp = new Date(temp.time);
            let alert = new AlarmStatus(
              id,
              temp.status,
              timestamp.temp.message
            );
            alertsCollection(alert);
          }
          return alertsCollection;
        }
      } catch (err) {
        console.log(err);
      }
    },
    /** Gets the current alarm status */
    async getAlarmStatus(item, start, end) {
      try {
        const query = {
          range: this.rangeMS,
          device: item.device
        };
        let ret;

        let res = await this.$customAPI.getAlertStatus(query);

        if (res && res.status === 200) {
          if (res.data && res.data.length > 0) {
            let statusMessage = res.data[0];

            ret = new AlarmStatus();
            ret.status = statusMessage.status;
            ret.message = statusMessage.message;
            ret.timestamp = new Date(statusMessage.time);
          } else {
            ret = {
              // Default is stopped
              status: "stopped",
              message: ""
            };
          }
        }
        return ret;
      } catch (err) {
        console.log(err);
      }
    }
  }
});

/** Export the installation methods and get instance (singleton) */
export default {
  install(Vue, options) {
    Vue.prototype.$alerting = instance;
  },

  getInstance() {
    return instance;
  }
};
