

const manualEditType = {
  ADD_CIRCULAR: "addCircular",
  MODIFY_CIRCULAR: "modifyCircular",
  ADD_POLYGON: "addPolygon",
  MODIFY_POLYGON: "modifyPolygon"
};

const geoListViewMode = {
  SELECT: "select",
  DRAWING: "drawing",
  MANUAL: "manual",
}

const geofenceType = {
  CIRCULAR: "circular",
  POLYGON: "polygon",
}

const geoCatFormType = {
  ADD: "add",
  MODIFY: "modify"
}

const ListMethod = {
  BADGE: "badge",
  TABLE: "table",
}

export {manualEditType, geofenceType, geoCatFormType, geoListViewMode, ListMethod}