/** A Wrapper for Custom API 
 * 
 * Note: Should Be Part of Customer Library 
 */

import Vue from "vue";
import urlJoin from "url-join";
import axios from "axios";


/** Note: these all get overwritten by Env variables anyway  */
let HOST_URL = "https://poc.aarcomm.io";  
let API_URL = "/api"
let API_VERSION = "/v1"
const PLUGIN_NAME = "cleanharbors-lonestar";



let instance;


const DEFAULT_TIMEOUT_MS = 5000; // Default Timeout  

export const customAPI = ({
  ...options
}) => {

  if (instance)
    return instance;
  // Create A Component Wrapper around the API 
  instance = new Vue({

    data() {
      return {
        //api: null, // Reference to the base API 
        response: null,
        config: {

        },
        url: null,

      }
    },
    created() {
      this.config = {
        ...options
      };
      let host = options.apiHost;
      if (!host)
        host = HOST_URL; // Fallback to Default 

      this.apiURL = options.apiEndpoint;
      if (!this.apiURL)
        this.apiURL = API_URL; // Falls back to default. if apiEnd point not specified. 
      //let path = this.apiURL + "custom";
      let version = API_VERSION;
      let plugin = PLUGIN_NAME; // Custom Plugin Name for customer 
      //let path = `${this.apiURL}${plugin}${version}`; 
      let path = urlJoin(this.apiURL, plugin, version);
      this.url = new URL(path, host);


    },
    computed: {
      host() {
        if (this.config) {
          return this.config.host || this.config.apiHost;
        }
        return HOST_URL;
      },
      api() {
        return this.$api;
      },
      apiToken() {
        return this.api.apiToken;
      },

      defaultParams() {
        return this.api.defaultParams;
      },
      defaultHeaders() {
        return this.api.defaultHeaders;
      }

    },
    methods: {
      encodeURL(host, apiEndpoint, version, plugin) {

        if (!host)
          host = HOST_URL; // Fallback to Default 

        let apiURL = apiEndpoint;

        //let path = `${this.apiURL}${plugin}${version}`; 
        let path = urlJoin(apiURL, plugin, version);
        return new URL(path, host);
      },
      encodeParams(params) {
        return this.api.encodeParams(params);
      },
      logError(err) {
        console.log(err);
      },
      handleErrorResponse(err){
        if(err)
          return err.response; 
      },

      async getToken() {
        return this.$api.getToken();
      },

      // GET METHODS 
      async getAlertStatus(params) {
        try {

          await this.getToken();
          this.response = await axios.get(this.url + "/alerts", {
            params: this.encodeParams(params),
            headers: {
              Authorization: `Bearer ${this.apiToken}`
            },
          });
        } catch (err) {
          this.logError(err);
          this.response = this.handleErrorResponse(err); 
        }
        return this.response;
      },
      /**
       * Get Asset By ID 
       * Retrieves Asset by ID => User can either specify ID or UID 
       * 
       * @param {*} params 
       * @returns 
       */
      async getPoints(params) {
        try {
          
          await this.getToken();


          this.response = await axios.get(this.url + "/realtime/pressure", {
            params: this.encodeParams(params),
            headers: {
              Authorization: `Bearer ${this.apiToken}`
            },
            timeout: DEFAULT_TIMEOUT_MS
          });
        } catch (err) {
          this.logError(err);
          this.response = this.handleErrorResponse(err); 
        }
        return this.response;
      }
    }

      
  })
  return instance;
}


/** Finally Export to Vue  */
export const CustomAPI = {
  install(Vue, options) {
    Vue.prototype.$customAPI = customAPI(options);
  }
}
