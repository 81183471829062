


import Vue from "vue"; 
const Influx = require('influx');


const defaultOptions ={

    host: 'localhost',
    port: 8086,
    protocol: 'http',
     username: "", // Leave these empty! Configure externally 
     password: "", 
    schema: [
    ]

   }; 


let influxInstance; // Singleton 

export const getInstance = ()=>{
    return influxInstance; 
}

const initInflux = (options)=>{
    let config; 
      if(!options)
        config = defaultOptions;
      else {
        config = {... defaultOptions};
        Object.assign(config,options); 
      } 
     let influx = new Influx.InfluxDB(config);      

     return influx; 
}

const influxdb = (config)=>{
    let instance = getInstance();
    try{
        if(!instance){
        /// Intialize the influx instance here 
            instance = initInflux(config);
        }
    }
    catch(err){
        console.log(err); 
    }
    return instance; 
}



/** Finally Export to Vue  */
export const InfluxAPI = {
    install(Vue, options) {
        Vue.prototype.$influx = influxdb(options);
    }
}





