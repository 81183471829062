
/** Creates a Vuex Module to manage and Store reports  */

import Vue from "vue"; 


const reporting = {
    state: () =>({
        reports:[], 
        age: Date.now(), 
    }), 
    getters: {
          /** Report Plugins */
        reports:function(state){return state.reports} 
    }, 
    mutations: {
        updateReports(state, [variable, value]) {
            state.age = Date.now();
            Vue.set(state, variable, value);
          },
    }
}

export default reporting; 
