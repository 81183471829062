export default  {
  data: function () {
    return {
      
    }
  },
  methods: {
      async getDeviceGroups(params) {
        let res;
        try {
          res = await this.client.get("/device/groups", params);     
        } catch (err) {
          this.logError(err);
        }
        return res;
      },

      async getDeviceGroupByUID(uid, params) {
        let res;
        try {
          res = await this.client.get(`/device/groups/${uid}`, params);     
        } catch (err) {
          this.logError(err);
        }
        return res;
      },

      async createDeviceGroup(data) {
        let res;
        try {
          res = await this.client.create(`/device/groups`, data);   
        } catch (err) {
          this.logError(err);
        }
        return res;
    },

    async updateDeviceGroupByUID(uid,data,params) {
      let res;
        try {
          res = await this.client.update(`/device/groups/${uid}`, data, params); 
            
        } catch (err) {
            this.logError(err);
        }

        return res;
    },

    async deleteDeviceGroupByUID(uid,params) {
        let response;
        try {
            response = await this.client.delete(`/device/groups/${uid}`,params); 
        } catch (err) {
            this.logError(err);
        }

        return response;
    },
  }
}
