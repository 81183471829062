


/** Implement your API Extensions Here, then Implement them as a Mixin */


export default  {
    data: function () {
      return {
        
      }
    },
    methods:{
        async getGeofences(params) {
            try {
                
                this.response = await this.client.get("/geofence", params); 
                
            } catch (err) {
                this.logError(err);
            }

            return this.response;
        },

        async getGeofenceByUID(uid, params) {
            let res;
            try {
              res = await this.client.get(`/geofence/${uid}`, params);     
            } catch (err) {
              this.logError(err);
            }
            return res;
        },
        
        async createGeofence(data, params) {
            let response;
            try {
                response = await this.client.create("/geofence",data,params); 
                
            } catch (err) {
                this.logError(err);
            }

            return response;
        },

        async updateGeofenceByUID(data, uid) {
            let response;
            try {
                response = await this.client.update(`/geofence/${uid}`, data); 
                
            } catch (err) {
                this.logError(err);
            }

            return response;
        },

        async deleteGeofenceByUID(uid,params) {
            let response;
            try {
                response = await this.client.delete(`/geofence/${uid}`,params); 
            } catch (err) {
                this.logError(err);
            }

            return response;
        },

        async getGeofenceEvents(params) {
            try {
                this.response = await this.client.get("/geofence/events",params); 
                
            } catch (err) {
                this.logError(err);
            }

            return this.response;
        },

    }
  }