<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { Polygon, PolygonGroup, Point, GeofenceCategory, CircularGeofence} from "./lib/geofencing_lib";
import { Alert, Rule, DeviceGroup } from './lib/rule_lib';




import hydrovac_lib from "./lib/hydrovac_lib/index";
import { IoTDevice } from "./lib/iot/models";
import  statusHelper  from "./lib/hydrovac_lib/statusHelper";  

const {fleetStatusEnum, FleetStatus} =statusHelper;

const DEFAULT_TIMER_TIMEOUT = 30000; // TODO: get from config
const DEFAULT_BRANCH_ID = "1";
const DEFAULT_TEAM_ID = "1";
const app = {
  name: "App",
  data() {
    return {
      inventory: undefined,
      myRecentActivity: undefined,
      fleetActivity: undefined,
      status: undefined,
      branches: undefined,
      timerHandle: undefined,
      updatePeriod: DEFAULT_TIMER_TIMEOUT,

      loading: false,
      userSettings: undefined,
      settings: {
        timerTimout: DEFAULT_TIMER_TIMEOUT,
      },
      //defaultConnection: "developers",
      availableRoles: [
        { key: "1", label: "Operator", value: "viewer" },
        { key: "2", label: "Branch Admin", value: "editor" },
        { key: "3", label: "Admin", value: "admin" },
      ],
    };
  },
  computed: {
    sensorMap() {
      let myMap;
      let temp = this.$store.getters.inventory;
      if (!temp) return myMap;
      temp = temp.map((item) => {
        return {
          device: item.device,
          sensors: item.sensors,
        };
      });

      myMap = {};
      for (let sensor of temp) {
        if (!myMap[sensor.device]) {
          if (typeof sensor.sensors === "string")
            myMap[sensor.device] = JSON.parse(sensor.sensors);
          else {
            myMap[sensor.device] = sensor.sensors;
          }
        }
      }
      return myMap;
    },
    activeDevices() {
      if (this.$store.getters.inventory)
        return this.$store.getters.inventory.filter((item) => item.status === "active");
    },

    myTeam() {
      return this.$store.getters.selectedBranch
        ? this.$store.getters.selectedBranch.branch_id
        : DEFAULT_BRANCH_ID;
    },

    myTeamId() {
      return this.$store.getters.selectedBranch
        ? this.$store.getters.selectedBranch.branch_id
        : DEFAULT_BRANCH_ID;
    },
    myBranchId() {
      return this.$store.getters.selectedBranch
        ? this.$store.getters.selectedBranch.branch_id
        : DEFAULT_BRANCH_ID;
    },
    myBranch() {
      return this.$store.getters.selectedBranch;
    },
    myDevices() {
      if (this.$store.getters.inventory)
        return this.$store.getters.inventory.filter(
          (item) => item.branch_id === this.myBranchId
        );
      else return [];
    },
  },
  mounted() {
    //this.$api.onLoggedInCallback = this.onLoad;
  },
  methods: {
    getUserRoleAlias(userRole) {
      // TODO: Create a Library function to extract the role "Nickname"
      if (!userRole) return "viewer";
      let role = userRole.toLowerCase(); // Force to lower case
      if (role == "admin") {
        return "Administrator";
      } else if (role == "editor" || role == "branch admin") {
        return "Branch Admin";
      } else if (role === "user" || role === "viewer") {
        return "Operator";
      } else {
        return "View Only";
      }
    },
    getUserRole(userRole) {
      // TODO: Create a Library function to extract the role "Nickname"
      if (!userRole) return "viewer";
      let role = userRole.toLowerCase(); // Force to lower case
      if (role == "admin") {
        return "admin";
      } else if (role == "editor" || role == "branch admin") {
        return "editor";
      } else if (role === "user" || role === "viewer") {
        return "user";
      } else {
        return "viewer";
      }
    },
    async ssoLogin() {
      try {
        console.log(this.$auth);
        //return await thisinventory.$auth.ssoLogin();
      } catch (err) {
        console.log(err);
      }
    },
    async update() {
      try {
        let params = {
          end: Date.now(),
          start: Date.now() - (this.settings.timerTimout || DEFAULT_TIMER_TIMEOUT),
        };
        let concurrentPool = [this.updateDeviceActivity(), this.updateFleetStatus()];

        Promise.all(concurrentPool);
        /*
          await this.updateDeviceActivity();
          await this.updateFleetStatus(); 
          */
      } catch (err) {
        console.log(err);
      }
    },
    async updateDeviceActivity(params) {
      if (!params) {
        params = { start: "now()-30s" };
      }
      let res = await this.$api.getDeviceActivity(params);
      if (res.status === 200) {
        console.log(res.data);
        let filteredData = this.filterActivity(res.data);

        this.$store.commit("updateRecentActivity", filteredData);

        this.myRecentActivity = this.$store.getters.activity.map((item, index) => {
          return { ...item, index };
        });
      }
    },

    async updateFleetStatus(params) {


      this.status = await this.getFleetStatus(params);
      
      if(this.status){
        this.$store.commit("updateStatus", this.status);
      }

      /*
      let resp = await this.$api.getFleetStatus(params);

      if (resp.status === 200) {

        this.status = resp.data.map((item, index) => {
          return { ...item, index }; // TODO: Commit to Store
        });
        this.$store.commit("updateStatus", this.status);
      }
      */
    },
    storeProfile() {
      try {
        if (this.$auth.user.darkMode != this.$store.state.darkMode)
          this.$store.commit("toggle", "darkMode");

        if (this.$auth.user.theme)
          this.$store.commit("updateTheme", this.$auth.user.theme);
        if (this.$auth.user.userLogo)
          this.$store.commit("updateLogo", ["lg", this.$auth.user.userLogo]);
        if (this.$auth.user.userLogoSm)
          this.$store.commit("updateLogo", ["sm", this.$auth.user.userLogoSm]);

        if (this.$auth.branch_id && this.$store.getters.branches) {
          // Select Default Branch
          // // If Branch Not Set, then commit the users default branch.
          //if (!this.myBranchId) {
          console.log("Selecting branch ", this.$auth.branch_id);
          this.$store.commit("selectBranch", this.$auth.branch_id);
          //}
        }
      } catch (err) {
        console.log(err);
      }
    },

    async getWeeklyFleetActivityAsync(params) {
      try {
        let res = await this.$api.getWeeklyFleetActivity(params);
        var tempWeekly = [];
        if (res.status === 200) {
          console.log(res.data);

          for (var i = 0; i < res.data.length; i++) {
            tempWeekly.push(res.data[i].active);
          }
          this.$store.commit("updateWeeklyActivity", res.data);
          // Should return an Array of Objects. In this case it is singular array
          // this.dataSets.weeklyActivity = res.data[0];
        }
      } catch (err) {
        this.$log(err);
      }
      return this.$store.getters.weeklyfleetactivity;
    },

    async getFleetActivityAsync() {
      try {
        let res = await this.$api.getFleetActivity({});
        if (res) {
          this.fleetActivity = res.data[0];
          // TODO: Commit to Data store Here.
        }
      } catch (err) {
        console.log(err);
      }
      return this.fleetActivity;
    },

    async getFleetStatus(params) {
      try {
        let res = await this.$api.getFleetStatus(params);
        if (res.status === 200) {
          console.log(res.data);
          // KH: This should only return currently "activated" devices 
          // For now we are just going to filter on the client side utnil we get it fixed

          let temp = res.data; 
          temp = res.data.map((item, index)=> {
            let statusObject = new FleetStatus(item.device,item.branch_id, item.status);
            statusObject.id = index; // ??
            statusObject.speed = item.speed; 
            statusObject.state = item.state; 
            return statusObject; 
          }); 

          this.status = temp; // Set the collection 

          
          // TODO: Commit to Store.
        }
      } catch (err) {
        console.log(err);
      }
      return this.status;
    },
    async loadBranches(params) {
      try {
        console.log("Loading Branches");
        let res = await this.$api.getBranches(params);
        if (res && res.status === 200) {
          this.branches = [...res.data];

          // TODO: Commit Branches to Local Store
          this.$store.commit("updateBranches", this.branches);
        }
      } catch (err) {
        this.$log(err);
      }
      return this.branches;
    },

    async loadGeofences(params) {
      try {
        let res = await this.$api.getGeofences(params);
        if (res && res.status === 200) {
          this.geofences = [...res.data];

          // TODO: Commit Branches to Local Store

          this.$store.commit("updateData", ["geofences", this.geofences]);
        }
      } catch (err) {
        this.$log(err);
      }
      return this.geofences;
    },

    async loadCircularGeofences(params) {
      let result = [];
      try {
        let res = await this.$api.getGeofences(params); // TODO: h_test: once the api support polygon, we need to change this.
        if (res && res.status === 200) {
          res.data.forEach((_geofence) => {
            result.push(
              new CircularGeofence(
                _geofence.id,
                _geofence.name,
                _geofence.coordinates.center.lat,
                _geofence.coordinates.center.lng,
                _geofence.coordinates.radius,
                _geofence.geofenceTags_uids,
                _geofence.description,
                _geofence.createdAt,
                _geofence.lastModify,
                _geofence.uid,
                _geofence.owner
              )
            );
          });
          this.$store.commit("updateData", ["circularGeofences", result]);
        }
      } catch (err) {
        this.$log(err);
      }
      return result;
    },

    // h_todo: either load both polygons and circulars in one call. or add a param to getGeofence
    async loadPolygons(params) {
      try {
        let res = await this.$api.getGeofences(params);
        if (res && res.status === 200) {
          let groups = [];
          res.data.forEach((_group) => {
            let group = new PolygonGroup(
              _group.id,
              _group.name,
              [],
              _group.categories,
              _group.description,
              _group.createdAt,
              _group.lastModify
            );
            _group.polygons.forEach((_polygon) => {
              let polygon = new Polygon(_polygon.poly_id, []);
              _polygon.points.forEach((_point) => {
                let point = new Point(_point.id, _point.lat, _point.lng);
                polygon.addPoint(point);
              });
              group.addPolygon(polygon);
            });
            groups.push(group);
          });
          this.$store.commit("updateData", ["polygonGroups", groups]);
        }
      } catch (err) {
        this.$log(err);
      }
      return this.polygonGroups;
    },

    // "/geofence/tags"
    async loadGeofenceCategories(params) {
      let geofenceCategories = [];
      try {
        let res = await this.$api.getGeofenceCategories(params);
        if (res && res.status === 200) {
          res.data.forEach((_cat) => {
            geofenceCategories.push(
              new GeofenceCategory(_cat.id, _cat.name, _cat.description, _cat.uid)
            );
          });
          this.$store.commit("updateData", ["geofenceCategories", geofenceCategories]);
        }
      } catch (err) {
        this.$log(err);
      }
      return geofenceCategories;
    },

    async addGeofenceCategory(body) {
      try {
        let res = await this.$api.createGeofenceCategory(body);
        if (res && res.status === 201) {
          await this.loadGeofenceCategories();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async modifyGeofenceCategory(uid, body, params) {
      try {
        let res = await this.$api.updateGeofenceCategoryByUID(uid, body, params);
        if (res && res.status === 204) {
          await this.loadGeofenceCategories();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async deleteGeofenceCategory(uid) {
      try {
        let res = await this.$api.deleteGeofenceCategoryByUID(uid);
        if (res && res.status === 204) {
          await this.loadCircularGeofences();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async loadAlerts(params) {
      let alerts = [];
      try {
        let res = await this.$api.getAlerts(params);
        if (res && res.status == 200) {
            res.data.forEach(json => {
              alerts.push(new Alert(json));
            })
            this.$store.commit("updateData", ["alerts", alerts]);
        }
      } catch (err) {
        console.log(err);
      }
    },

    async createAlert(body, params) {
      try {
        let res = await this.$api.insertAlert(body, params);
        if (res && res.status === 201) { 
          await this.loadAlerts();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async modifyAlert(uid, body, params) {
      try {
        let res = await this.$api.updateAlertByUID(uid, body, params);
        if (res && res.status === 204) {
          await this.loadAlerts();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async removeAlert(uid, params) {
      try {
        let res = await this.$api.deleteAlertByUID(uid, params);
        if (res && res.status === 204) {
          await this.loadAlerts();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async enableAlert(uid, params) {
      try {
        let res = await this.$api.enableAlertByUID(uid, params);
        if (res && res.status === 204) {
          await this.loadAlerts();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async disableAlert(uid, params) {
      try {
        let res = await this.$api.disableAlertByUID(uid, params);
        if (res && res.status === 204) {
          await this.loadAlerts();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async loadDeviceGroups(params) {
      let deviceGroups = [];
      try {
        let res = await this.$api.getDeviceGroups(params);
        if (res && res.status === 200) {
          res.data.forEach(json => {
            deviceGroups.push(new DeviceGroup(json));
          });
          this.$store.commit("updateData", ["deviceGroups", deviceGroups]);
        }
      } catch (err) {
        this.$log(err);
      }
    },

    async addDeviceGroup(body) {
      try {
        let res = await this.$api.createDeviceGroup(body);
        if (res && res.status === 201) {
          await this.loadDeviceGroups();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async modifyDeviceGroup(uid, body, params) {
      try {
        let res = await this.$api.updateDeviceGroupByUID(uid, body, params);
        if (res && res.status === 204) {
          await this.loadDeviceGroups();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async deleteDeviceGroup(uid) {
      try {
        let res = await this.$api.deleteDeviceGroupByUID(uid);
        if (res && res.status === 204) {
          await this.loadDeviceGroups();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async getGeofenceEvents(params) {
      try {
        let res = await this.$api.getGeofenceEvents(params);

        if (res && res.status === 200) {
          return res.data;
          // TODO: Commit to Memory???
        }
      } catch (err) {
        console.log(err);
      }
    },

    async loadRules(params) {
      let rules = [];
      try {
        let res = await this.$api.getRules(params);
        if (res && res.status == 200) {
            res.data.forEach(json => {
              rules.push(new Rule(json));
            })
            this.$store.commit("updateData", ["rules", rules]);
        }
      } catch (err) {
        console.log(err);
      }
    },

    async createRule(body, params) {
      try {
        let res = await this.$api.insertRule(body, params);
        if (res && res.status === 201) { 
          await this.loadRules();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async modifyRule(uid, body, params) {
      try {
        let res = await this.$api.updateRuleByUID(uid, body, params);
        if (res && res.status === 204) {
          await this.loadRules();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async removeRule(uid, params) {
      try {
        let res = await this.$api.deleteRuleByUID(uid, params);
        if (res && res.status === 204) {
          await this.loadRules();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async enableRule(uid, params) {
      try {
        let res = await this.$api.enableRuleByUID(uid, params);
        if (res && res.status === 204) {
          await this.loadRules();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async disableRule(uid, params) {
      try {
        let res = await this.$api.disableRuleByUID(uid, params);
        if (res && res.status === 204) {
          await this.loadRules();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async loadReports(params) { 
      try {
        let res = await this.$api.getReports(params);
        if (res.status == 200 && res.data) {
          this.reportData = res.data.map((item, id) => {
            return { ...item };
          });
          this.$store.commit("updateReports", ["reports", this.reportData]);
        }
      } catch (err) {
        console.log(err);
      }
      return this.reportData;
    },
    async loadUsers(params) {
      try {
        let res = await this.$api.getUsers();

        if (res.status === 200) {
          let users = [...res.data];
          this.$store.commit("updateData", ["users", users]);
        }
      } catch (err) {
        console.log(err);
      }
      return this.$store.getters.users;
    },
    async loadSettings(params) {
      try {
        let settingsRes = await this.$api.getSettings(params); // Start the next a
        if (settingsRes.status == 200) {
          this.userSettings = { ...settingsRes.data };
          console.log(this.userSettings);

          // TODO: Store Settings into Data Store Here for offline
          this.$store.commit("updateData", ["settings", this.userSettings]);
          // TODO: Update the User Theme based on this response.
          if (this.userSettings.theme)
            this.$store.commit("updateTheme", this.userSettings.theme);
        }
      } catch (err) {
        console.log(err);
      }
      return this.userSettings;
    },
    async loadDashboards(params) {
      try {
        let res = await this.$api.getDashboards({ type: "grafana" });
        if (res && res.status === 200) {
          this.$store.commit("updateDashboards", res.data);
        }
      } catch (err) {
        console.log(err);
      }
      return this.$store.getters.dashboards;
    },
    async loadInventory(params) {
      try {
        console.log("Loading Inventory");
        let res = await this.$api.getInventory(params);

        if (res && res.status === 200) {
          this.inventory = res.data.map((item, index) => {
            let temp = new IoTDevice(item.device, item.branch_id); 
            temp.index = index; 
            Object.assign(temp, item); 
            return temp; 
          });
          this.$store.commit("updateData", ["inventory", this.inventory]);
          
        }
      } catch (err) {
        console.log(err);
      }
      return this.inventory;
    },

    /** Helper Function to Filter Activity Only Relevant to the App... TODO: Should Filter on The Server Side!*/
    filterActivity(activityList, options) {
      return activityList.filter((item) => {
        const eventList = [
          "waterOn",
          "waterOff",
          "radioUp",
          "radioDown",
          "ConnectionUp",
          "WandEvent",
          "WandIdleEvent",
          "WandIdleChanged",
        ];
        return eventList.find((event) => event === item.name);
      });
    },
    async loadEquations(params) {
      try {
        console.log("Loading Equations");
        let res = await this.$api.getEquations(params);
        console.log(res);
        if (res && res.status === 200) {
          // Commit the equations to data store here
          let equations = res.data;
          if (equations) {
            this.$store.commit("updateEquations", equations);
            this.$equationMGR.init(equations);
            console.log(this.$equationMGR);
          }
        }

        console.log(res);
      } catch (err) {
        console.log(err);
      }
    },

    async loadDeviceActivity(params) {
      try {
        let res = await this.$api.getDeviceActivity(params);
        if (res && res.status === 200) {
          let filteredData = this.filterActivity(res.data);
          this.myRecentActivity = filteredData.map((item, index) => {
            return { ...item, index };
          });
          this.$store.commit("updateRecentActivity", res.data);
        }
      } catch (err) {
        console.log(err);
      }
    },

    async addGeofence(body) {
      try {
        let res = await this.$api.createGeofence(body);
        if (res && res.status === 201) {
          await this.loadCircularGeofences();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async modifyGeofence(body, uid) {
      try {
        let res = await this.$api.updateGeofenceByUID(body, uid);
        if (res && res.status === 204) {
          await this.loadCircularGeofences();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async deleteGeofence(uid, params) {
      try {
        let res = await this.$api.deleteGeofenceByUID(uid, params);
        if (res && res.status === 204) {
          await this.loadCircularGeofences();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async onLoad() {
      /// Try Load the Dashboards in the background
      // TODO: Load all the background info for the app that we can "Cache" Locally
      try {
        this.loading = true;
        console.log("logged in");
        console.log(this.$auth.user);
        console.log(this.$store.state);
        // TODO: Attempt to Log-in to Grafana and Remaining Backend?

        //await this.ssoLogin(); // Perform Signle Sign-on
        this.storeProfile();

        let concurrentPool = [];

        concurrentPool.push(this.loadInventory());
        concurrentPool.push(this.loadBranches());
        concurrentPool.push(this.loadDashboards());
        concurrentPool.push(this.loadEquations());

        //concurrentPool.push(this.loadDeviceActivity());
        concurrentPool.push(this.loadUsers());
        
        // load geofences
        //concurrentPool.push(this.loadGeofenceCategories());
        //concurrentPool.push(this.loadCircularGeofences());

        await Promise.all(concurrentPool);

        this.storeProfile();

        /*
        await this.loadInventory();

        await this.loadBranches(); 


        this.storeProfile(); // Store Profile at this point 

        await this.loadDashboards();

        await this.loadDeviceActivity();

        await this.loadUsers();
        */

        await this.update();
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async addUser(user, params) {

      let res;  
      try {
        // Perform the Application Specific User Add Function
        user.email_verified = true;
        user.verify_email = false;
        res = await this.$api.createUser(user, params);
        console.log(res);
        return res;
      } catch (err) {
        console.log(err); 
        res = err.response; 
      }
      return res; 
    },
  },
  created() {

    /*
    this.timerHandle = setInterval(() => {
      this.update();
    }, this.updatePeriod);
    */
  },
  destroyed() {
    if (this.timerHandle) {
      clearInterval(this.timerHandle);
    }
  },
};

export const Application = {
  install(Vue, options) {
    console.log("Installing prototype");
    Vue.prototype.$app = app;
  },
};

export default app;
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
