const fieldList = ["id", "uid", "rule_id", "enabled", "name", "description", "topics", "data", "metadata", "rule", "branch_id", "created", "updated", "alert_uid", "deviceGroups_uids"]


class Rule {
    constructor(data) {
        for (var key of Object.keys(data)) {
            if (fieldList.includes(key)) {
                this[`_${key}`] = data[key];
            }
        }
    }

    get id () {
        return this._id;
    }

    get uid () {
        return this._uid;
    }

    get rule_id () {
        return this._rule_id;
    }

    get enabled () {
        return this._enabled;
    }

    get name () {
        return this._name;
    }

    get description () {
        return this._description;
    }

    get topics () {
        return this._topics;
    }

    get data () {
        return this._data;
    }

    get metadata () {
        return this._metadata;
    }

    get rule () {
        return this._rule;
    }

    get branch_id () {
        try {
            return this._branch_id.toString();
        } catch {
            return "";
        } // todo: change database so that branch_id is a string in the rule db
    }

    get created () {
        return this._created;
    }

    get updated () {
        return this._updated;
    }

    get alert_uid () {
        return this._alert_uid;
    }

    get deviceGroups_uids () {
        return this._deviceGroups_uids;
    }

    /**
    * @returns {{
    *   field: String,
    *   logic: String,
    *   value: String,
    * }}
    * // h_todo_lonestar_101
    */
    get logics() {
        return this._data.logic;
    }

    get logicNaturalLanguage() {
        return this._data.logicString;
    }

}

export { Rule }


