

import { getInstance } from "../plugins/auth/index";


// TODO: get these tags from config.json or .env. 
const SYSADMIN_TAG ="sysadmin"; 
const ADMIN_TAG = "admin"; // Tag that indicates access level to a route
const EDITOR_TAG = "editor"; 
const USER_TAG = "viewer"; // Note: viewer / user a synonomous in our system. 



const isAdmin = () => {
    //console.log("Checking admin");
    let authService = getInstance();
    if (!authService)
        return false;
    // TODO: authService.isAdmin would also work 
    if (authService.role == ADMIN_TAG || authService.role== SYSADMIN_TAG) {

        return true;
    }
    else {
        //console.log("User Only account");
        return false;
    }
}

const permissionsCheck = (to, from, next) => {
    let authService = getInstance();
    if (!authService)
        return false;
    console.log(authService);
    if (authService.role == ADMIN_TAG) {
        next()
        return true;
    }
    else {
        console.log("User Only account");
        return false;
    }
}



export default {
    permissionsCheck,
    isAdmin,
    ADMIN_TAG, 
    EDITOR_TAG,
    USER_TAG, 
}