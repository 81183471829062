


/** Helper Library to Parse data and create plots */

import {IoTDevice} from "../iot/models"; 


class HydrovacModel {

    // Hydrovacs Have an IoT Device 
    deviceInfo; 
    // Hydrovacs Have a Radio Device 
    radioInfo; 
    // Hyrovacs Have a Collection of Sensors 

    sensors; 
    
    // Hydrovacs Have a Colleciton of Data; 
    
    // Hydrovacs Have a Alerts associated with them 

    constructor(device,branch_id){
        this.deviceInfo = new IoTDevice(device,branch_id);
    }
}


export default {
    HydrovacModel,
}
