export default  {
  data: function () {
    return {
      
    }
  },
  methods: {
    async getGeofenceCategories(params) {
      let res;
      try {
        res = await this.client.get("/geofence/tags", params);     
      } catch (err) {
        this.logError(err);
      }
      return res;
    },

    async getGeofenceCategoryByUID(uid, params) {
      let res;
      try {
        res = await this.client.get(`/geofence/tags/${uid}`, params);     
      } catch (err) {
        this.logError(err);
      }
      return res;
    },

    async createGeofenceCategory(data) {
      let res;
      try {
        res = await this.client.create(`/geofence/tags`, data);   
      } catch (err) {
        this.logError(err);
      }
      return res;
    },

    async updateGeofenceCategoryByUID(uid,data,params) {
      let res;
        try {
          res = await this.client.update(`/geofence/tags/${uid}`, data, params); 
            
        } catch (err) {
            this.logError(err);
        }

        return res;
    },

    async deleteGeofenceCategoryByUID(uid,params) {
        let response;
        try {
            response = await this.client.delete(`/geofence/tags/${uid}`,params); 
        } catch (err) {
            this.logError(err);
        }

        return response;
    },
  }
}