const fieldList = ["id", "uid", "name", "description", "action", "message", "recipients", "branch_id", "created", "updated", "last_executed", "cooldown", "enabled"]

class Alert {
    constructor(data) {
        for (var key of Object.keys(data)) {
            if (fieldList.includes(key)) {
                this[`_${key}`] = data[key];
            }
        }
    }

    // ["abc", "def"] => "abc,def" (comma separated list without space)
    static castArrToStr(arr) {
        if (!arr.length) {
            return "";
        }
        let str = "";
        for (const val of arr) {
            str += `${val},`;
        }
        return str.slice(0, -1);
    }
    
    // delete the spaces around commas
    static deleteSpace(str) {
        return str.replace(/\s*,\s*/g, ',');
    }

    get id () {
        return this._id;
    }

    get uid () {
        return this._uid;
    }

    get name () {
        return this._name;
    }

    get description () {
        return this._description;
    }

    get action () {
        return this._action;
    }
    
    get message () {
        return this._message || "";
    }

    get recipients () {
        return this._recipients.replace(/,/g, `, `);
    }

    get branch_id () {
        return this._branch_id;
    }

    get created () {
        return this._created;
    }

    get updated () {
        return this._updated;
    }

    get last_executed () {
        return this._last_executed;
    }

    get cooldown () {
        return this._cooldown;
    }

    get enabled () {
        return this._enabled;
    }
    
    // custom
    get recipientsArray () {
        if (!this.recipients.length) {
            return [];
        }
        return Alert.deleteSpace(this.recipients).split(",");
    }

    get customMessageMode () {
        return this.message != "[Default message]"
    }
}

export { Alert }


