/** Define your Customized Sizebard Items Here
 *  This Has better Reactivity during development to make your edits here
 */
const sidebarConfig = [
  {
    _name: "CSidebarNavItem",
    name: "Home",
    to: "/home",
    icon: "cil-home"
  },
  /*
  {
    _name: "CSidebarNavItem",
    name: "Fleet",
    to: "/fleet", // TODO: this should be device / dashboards.
    icon: "cil-truck"
  },
  */
  {
    _name: "CSidebarNavDropdown",
    name: "Team",
    to: "/users",
    icon: "cil-user",
    roles: ["admin", "editor","viewer"],
    items: [
      {
        to: "/users",
        name: "Users"
      },
      
      // {
      //   to: "/branches",
      //   name: "Branches"
      // }
    ]
  },

  {
    _name: "CSidebarNavItem",
    name: "Rules",
    to: "/rules",
    icon: "cil-bell",

    roles: ["admin", "editor"],
    items: [
      /*
        {
          to: "/rules/metrics",
          name: "Rule Metrics"
        },
        */
    ]
  },

  /* KH: Not included in this build
      {
        _name: "CSidebarNavDropdown",
        name: "Analytics",
        to: "/analytics",
        icon: "cil-graph",
        roles: ["admin"], 
        items: [
          {
            _name: "CSidebarNavItem",
            name: "Key Metrics",
            to: "/analytics",
          },
           {
            _name: "CSidebarNavItem",
            name: "Route Analytics",
            to: "/analytics/routes",
          }
        ]
      },
      */
  // {
  //   _name: "CSidebarNavDropdown",
  //   name: "Advanced",
  //   icon: "cil-settings",
  //   // roles: ["admin"],
  //   items: [
  //     {
  //       _name: "CSidebarNavDropdown",
  //       name: "Geofence",
  //       to: "/app/geofence",
  //       icon: "cil-settings",
  //       // roles: ["admin","editor"],
  //       items: [
  //         {
  //           to: "/app/geofence",
  //           name: "Geofences"
  //         },
  //         {
  //           to: "/app/geofenceCategories",
  //           name: "Geofence Tags"
  //         }
  //       ]
  //     }
  //   ]
  // },

  {
    _name: "CSidebarNavDropdown",
    name: "Reports",
    to: "/reports",
    icon: "cil-chart",
    roles: ["admin", "editor", "viewer"],
    items: [
      {
        to: "/reports/daily-list",
        name: "By Truck"
      }
      /*
      {
        to: "/reports",
        name: "View All"
      },
      */
    ]
  },

  /*
  {
    _name: "CSidebarNavDropdown",
    name: "Advanced",
    //to: "/settings",
    icon: "cil-settings",
    roles: ["admin", "editor"],
    items: [
      {
        to: "/app/geofence",
        name: "Geofence"
      },

     
      
    ]
  },
  */

  {
    _name: "CSidebarNavDivider",
    _class: "m-2"
  },
  {
    _name: "CSidebarNavTitle",
    _children: ["More"]
  },
  /* KH: Hiding temporarily 
  {
    _name: "CSidebarNavItem",
    name: "Documentation",
    icon: "cil-book",
    to: "/documents"
  },
  */
  {
    _name: "CSidebarNavItem",
    name: "Provide Us Feedback",
    icon: "cil-envelope-closed",
    href: "mailto:feedback@aarcomm.com?subject=Aarcomm IoT Feedback"
  }
  // ]
  // }
];

export default sidebarConfig;
