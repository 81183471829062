import { getInstance } from "../plugins/auth/index";
import router from '../router/index'
import {ADMIN_TAG} from "../plugins/auth/roles"; 


const LOGIN_ROUTE = "/login"; 
const UNAUTHORIZED_REDIRECT_ROUTE ="/unauthorized"; 
const ADMIN_ROLE = ADMIN_TAG;  


export const authGuard = (to, from, next) => {
  const authService = getInstance();
  let parent; 

  /** Middleware Function that gets called to check OK to enter a route*/
  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {

      if(!authService.isVerified){        
        router.push(UNAUTHORIZED_REDIRECT_ROUTE);
        return next()
      }
      // TODO: Check if we have permission to enter the route first 
        console.log(to); 
        let meta = to.meta; // temp store of meta data object.  

        // First -> Check if any permissions are set in the route 
        // If any permissions set, we need to take the last known valid permissions 
        // If no permssions set, then we can proceed as normal as this assumes access to all users 
        
        if(to.matched.some(record=>record.meta.permissions)){

        /* If Child Permissions aren't set, then we must take the parents permissions 
        *  If the Child permissions are set, then we must take those because child permssions 
        *  Can differ from parent. 
        */
           if(!meta.permissions){ // if the Child permissions are not set on this route, get the parent's
           
            parent = to.matched.find(record=>record.meta.permissions); // Truthy value
            
            if(parent)
            {
              console.log("Inheriting the Parent Permissions");  
              meta = parent.meta; 
            }

           }


        }
        // Finally, Perform the Permission check before entering the route 
        if(!meta || !meta.permissions){
          // If there are no permissions set, then we can simply proceed as normal 
          return next(); 
        }
        else{ 
          // Permissions were set, therefore we must check if they match the user's role
          // Note: If User Role is admin, they can access any Route 
          console.log(authService.role); 
         
          if(meta.permissions.includes(authService.role) || authService.role==ADMIN_ROLE){
            return next();
          }
          else{
            // TODO: Redirect to Unauthorized Page 
            //router.push("/");
            console.log("Unauthorized"); 
            return next({path: '/home'}); // TODO: redirect to user's home???  
          }
          

        }


      return next(); // Must always call next!
    }

    // Otherwise, log in
    //authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    // 
    router.push(LOGIN_ROUTE); 
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};