
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex); 


// Import Custom Add-on Modules Here. 
//import fueling from "./modules/fueling";  For Fueling applications 
import reporting from './modules/reporting';  // For Managing Reports 
import iotDataStore from './modules/iotDataStore';
import cookieSettings from './modules/cookieSettings'; 
/** Test Data  */
/** Widget Data  for Front Admin Dashboard*/


import settings  from "../../config/defaultSettings.js" 

const defaultTheme = {...settings.settings}; 
console.log(defaultTheme); 




const state = {
  /** All the GUI level Elements here. Note: some these can and SHOULD be cached offline */
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  asideShow: false,
  darkMode: true, // This is Inverted TODO: Fix
  logos: {
    sm: defaultTheme.logo_sm || "",
    lg: defaultTheme.logo ||"",
    brand: "",
  },
  theme: {...defaultTheme},
  fixtoasts: { count: 0, toast: null },


    
}



const actions = {
  // No Actions Yet. 
}

/** Getters Commonly required UX elements stored in state  */
const getters = {
  logos: state=>state.logos,
  theme: state => state.theme,
  fixtoasts: state => state.fixtoasts,
  darkMode: state =>state.darkMode, 
  asideShow: state=>state.asideShow, 

}

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'

  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'

  },
  set(state, [variable, value]) {
    Vue.set(state, variable, value);
    //state[variable] = value

  },
  toast(state, data) {
    state.fixtoasts.count += 1;
    Vue.set(state.fixtoasts, "toast", data);
  },
  toggle(state, variable) {
    state[variable] = !state[variable]

  },


  updateLogo(state, [logo, value]) {
    Vue.set(state.logos, logo, value); // Ensures Propery is Reactive
    //state.logos[logo] = value; 
  }
  ,
  updateTheme(state, data) {
    if (data) {
      console.log("Setting Theme", data);
      state.theme.backColor = data.backColor;
      state.theme.highlight = data.highlight;
      Vue.set(state,"theme", data);

      console.log(state.theme);
    }

  },

}


/** INIT PLUGINS */

/** Create a Theme Storage Plugin here  */
const themePersistPlugin = createPersistedState({
  key: "app:theme", 
  paths: ['theme', 'darkMode', 'logos'], 
})


/** Cookie Persist Plug-in */
const cookieSettingsPersistPlugin = createPersistedState({
  key: "app:cookies", 
  paths: ['cookieSettings'], 
})


export default new Vuex.Store({
  
  modules:{
    //plugins: fueling, // TODO: Plugins Requires Refactoring in our app to fueling!
    reporting: reporting, // Include the Reporting Vuex Module 
    dataStore: iotDataStore, // Always include the iot data storage objects
    cookieSettings: cookieSettings,
  }, 

  state,
  //theme,
  getters,
  mutations,
  actions,
  plugins: [themePersistPlugin,cookieSettingsPersistPlugin],
})