


import {MS_PER_DAY, MS_PER_HOUR} from "@/components/time/constants"; 
import { rangeMS, pressureThreshold, warningThreshold } from "./appConfig";

const inventory = [
    {
        "name": 717422,
        "branch": "Delta, BC",
        "truck": 717422,
        "device": "240a7a6",
        "radio": "C220 900MHz",
        "data": 521.4,
        "status": "active",
        "view": null
    },
    {
        "name": 791508,
        "branch": "Delta, BC",
        "truck": 791508,
        "device": "10ed661",
        "radio": "C220 900MHz",
        "data": 1150,
        "status": "active",
        "view": null
    },
    {
        "name": 717201,
        "branch": "Delta, BC",
        "truck": 717201,
        "device": "30e2dbb",
        "radio": "C220 900MHz",
        "data": 15.9,
        "status": "active",
        "view": null
    },
    {
        "name": 7995,
        "branch": "Delta, BC",
        "truck": 7995,
        "device": "00c4d1be",
        "radio": "C220 900MHz",
        "data": 0,
        "status": "parked",
        "view": null
    }
];


const activity = [
    {
        "time": "Thu, 12 Nov 2020 07:25:12 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 130"
    },
    {
        "time": "Thu, 12 Nov 2020 02:29:49 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 128"
    },
    {
        "time": "Wed, 11 Nov 2020 22:10:30 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 127"
    },
    {
        "time": "Wed, 11 Nov 2020 22:09:16 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 129"
    },
    {
        "time": "Wed, 11 Nov 2020 21:54:08 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 130"
    },
    {
        "time": "Wed, 11 Nov 2020 21:51:51 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 128"
    },
    {
        "time": "Wed, 11 Nov 2020 21:49:54 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 127"
    },
    {
        "time": "Wed, 11 Nov 2020 09:27:42 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 127"
    },
    {
        "time": "Wed, 11 Nov 2020 03:19:12 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 128"
    },
    {
        "time": "Wed, 11 Nov 2020 03:03:34 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 127"
    },
    {
        "time": "Wed, 11 Nov 2020 01:02:19 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 127"
    },
    {
        "time": "Wed, 11 Nov 2020 00:57:23 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 130"
    },
    {
        "time": "Wed, 11 Nov 2020 00:42:15 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 129"
    },
    {
        "time": "Wed, 11 Nov 2020 00:40:40 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 128"
    },
    {
        "time": "Wed, 11 Nov 2020 00:38:12 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 129"
    },
    {
        "time": "Wed, 11 Nov 2020 00:35:56 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 127"
    },
    {
        "time": "Wed, 11 Nov 2020 00:11:11 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 130"
    },
    {
        "time": "Wed, 11 Nov 2020 00:08:13 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 128"
    },
    {
        "time": "Wed, 11 Nov 2020 00:06:38 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 128"
    },
    {
        "time": "Wed, 11 Nov 2020 00:05:13 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 128"
    },
    {
        "time": "Wed, 11 Nov 2020 00:04:10 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 130"
    },
    {
        "time": "Wed, 11 Nov 2020 00:00:51 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 129"
    },
    {
        "time": "Tue, 10 Nov 2020 23:58:55 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 129"
    },
    {
        "time": "Tue, 10 Nov 2020 23:55:35 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 128"
    },
    {
        "time": "Tue, 10 Nov 2020 23:54:53 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 127"
    },
    {
        "time": "Tue, 10 Nov 2020 23:54:11 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 129"
    },
    {
        "time": "Tue, 10 Nov 2020 23:50:19 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 128"
    },
    {
        "time": "Tue, 10 Nov 2020 22:39:59 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 128"
    },
    {
        "time": "Tue, 10 Nov 2020 22:27:41 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 127"
    },
    {
        "time": "Tue, 10 Nov 2020 21:59:12 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 127"
    },
    {
        "time": "Tue, 10 Nov 2020 21:57:38 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 130"
    },
    {
        "time": "Tue, 10 Nov 2020 21:56:24 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 128"
    },
    {
        "time": "Tue, 10 Nov 2020 20:19:41 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 127"
    },
    {
        "time": "Tue, 10 Nov 2020 20:15:39 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 128"
    },
    {
        "time": "Tue, 10 Nov 2020 20:12:07 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 130"
    },
    {
        "time": "Tue, 10 Nov 2020 20:11:05 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 130"
    },
    {
        "time": "Tue, 10 Nov 2020 20:08:26 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 128"
    },
    {
        "time": "Tue, 10 Nov 2020 20:04:13 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 129"
    },
    {
        "time": "Tue, 10 Nov 2020 20:02:28 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 128"
    },
    {
        "time": "Tue, 10 Nov 2020 20:01:56 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 127"
    },
    {
        "time": "Tue, 10 Nov 2020 19:59:49 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 129"
    },
    {
        "time": "Tue, 10 Nov 2020 19:45:14 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 130"
    },
    {
        "time": "Tue, 10 Nov 2020 19:40:08 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 130"
    },
    {
        "time": "Tue, 10 Nov 2020 19:39:15 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 127"
    },
    {
        "time": "Tue, 10 Nov 2020 19:38:11 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 127"
    },
    {
        "time": "Tue, 10 Nov 2020 19:37:08 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 129"
    },
    {
        "time": "Tue, 10 Nov 2020 19:07:40 GMT",
        "message": "GPS Signal Lost On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 128"
    },
    {
        "time": "Tue, 10 Nov 2020 18:47:58 GMT",
        "message": "Device 30e2dbb is Up On Truck #717201",
        "image": "images/lightning-bolt-100.png",
        "myId": "Truck 129"
    }
];



const status =[
    {
        "name": 717422,
        "branch": "Delta, BC",
        "truck": 717422,
        "device": "240a7a6",
        "radio": "C220 900MHz",
        "data": 521.4,
        "view": null,
        "speed": 0,
        "status": "stopped"
    },
    {
        "name": 791508,
        "branch": "Delta, BC",
        "truck": 791508,
        "device": "10ed661",
        "radio": "C220 900MHz",
        "data": 1150,
        "view": null,
        "speed": 72.3,
        "status": "active"
    },
    {
        "name": 717201,
        "branch": "Delta, BC",
        "truck": 717201,
        "device": "30e2dbb",
        "radio": "C220 900MHz",
        "data": 15.9,
        "view": null,
        "speed": 58.9,
        "status": "active"
    },
    {
        "name": 7995,
        "branch": "Delta, BC",
        "truck": 7995,
        "device": "00c4d1be",
        "radio": "C220 900MHz",
        "data": 0,
        "view": null,
        "speed": 88.1,
        "status": "active"
    }
]


const deviceInfo=[
    {
        "truck": 717422,
        "branch": "Delta, BC",
        "branch_id": "1",
        "device": "240a7a6",
        "radio": "C220 900MHz",
        "data": 521.4,
        "link": null,
        "metadata": {
            "make": "Kenworth",
            "model": "",
            "year": 2015,
            "description": "Beta Test"
        },
        "time": "2020-11-05T19:12:24.752Z",
        "imei": "864431044367169",
        "imsi": "302720509789815",
        "iccid": "89302720523062248392",
        "lastConnect": "2020-11-06T14:33:59.166Z",
        "status": "stopped"
    }
]

const radioInfo=[
        {
            "time": "2020-11-13T09:10:29.591Z",
            "rssi": -80,
            "stat": 0,
            "truck": 717201
        }

]

const rules =[
    {
      "id": 1,
      "name": "Wand Dropped 717422",
      "enabled": true,
      "deviceId": "240a7a6",
      "topic": "Wand",
      "description": "On Wand Drop Detected",
      "logic":{ //h_todo_lonestar_101: this is not in the RULE model
        "field":"wandDropped", 
        "logic":"eq", 
        "value": "true"  
      },
      "logicString": "If WandDropped is equal to true", //h_todo_lonestar_101: this is not in the RULE model 
      "action": "Send SMS",
      "settings": "778-714-4017",
      "message": "[Default message]",
      "actionImg": "images/sms-96.png",
    },
    {
      "id": 2,
      "enabled": false,
      "name": "Over Pressure 717422",
      "deviceId": "240a7a6",
      "topic": "Sensors",
      "description": "On Over Pressure Detected",
      "logic":{
        "field":"hose", 
        "logic":"gt", 
        "value": "2800",  
        "unit":" PSI"
      },
      "logicString": "If hose is greater than 2800 PSI",
      "action": "Send SMS",
      "settings": "778-714-4017",
      "message": "[Default message]",
      "actionImg": "images/sms-96.png",
    },
    {
      "id": 3,
      "enabled": true,
      "name": "Wand Dropped 717395",
      "deviceId": "30e2dbb",
      "topic": "Wand",
      "description": "On Wand Drop Detected",
      "logic":{
        "field":"wandDropped", 
        "logic":"eq", 
        "value": "true"  
      },
      "logicString": "If WandDropped is equal to true",
      "action": "Send Email",
      "settings": "khol.haggerty@aarcomm.com",
      "message": "[Default message]",
      "actionImg": "images/send-email-100.png",
    },
    {
      "id": 4,
      "enabled": true,
      "name": "Wand Dropped 717395",
      "deviceId": "30e2dbb",
      "topic": "Sensors",
      "description": "On Over Pressure Detected",
      "logic":{
        "field":"hose", 
        "logic":"gt", 
        "value": "2800",
        "unit":" PSI"
      },
      "logicString": "If hose is greater than 2800 PSI",
      
      "action": "Send SMS",
      "settings": "778-714-4017",
      "message": "[Default message]",
      "actionImg": "images/sms-96.png",
    },
  ];


  const SensorFields = [
    {id:"1", value: "hose"},
    {id:"2", value: "temperature"},
    {id:"3", value: "vacuum"},
];

const WandFields = [
    {id:"1", value: "wandDropped"},
    {id:"2", value: "wandReset"},
    {id:"3", value: "wandIdle"},
]

const Dashboards = [
    {id: "240a7a6", value: "https://poc.aarcomm.io/app/grafana/d/bW7fdyfMk/truck-717422-sensors-realtime?orgId=2"},
    {id: "30e2dbb", value: "https://poc.aarcomm.io/app/grafana/d/PQyPDY8Gz/truck-717395-sensors-realtime?orgId=2"}, 
    {id: "10ed661", value: "https://demo1.aarcomm.io/api/grafana/d/NQRZxsBMk/hydrovac-truck-717395-test?orgId=1"}, 
    {id: "00c4d1be", value: "https://demo1.aarcomm.io/api/grafana/d/NQRZxsBMk/hydrovac-truck-717395-test?orgId=1"}
]






/** Default Time Range for Dashboard 
 * TODO: Load these from Config / External Settings
 */
const defaultTimes = 
[
        //{id:"5m", value:"5m", label:"Last 5 Minutes"},
        //{id:"10m",value:"10m", label:"Last 10 Minutes"},
        //{ id: "30m", value: "30m", label: "Show Last 30 Minutes", range:30*MS_PER_MINUTE },
        { id: "1h", value: "1h", label: "Last 1 hour" , range: 1*MS_PER_HOUR},
        { id: "8h", value: "8h", label: "Last 8 hours",range: 8*MS_PER_HOUR },
        { id: "24h", value: "24h", label: "Last 24 hours",range: 1*MS_PER_DAY },
        { id: "2d", value: "2d", label: "Last 2 days",range: 2*MS_PER_DAY },
        { id: "7d", value: "7d", label: "Last 7 days", range: 7*MS_PER_HOUR }, 
        { id: "30d", value: "30d", label: "Last 30 Days", range: 30* MS_PER_DAY}

      ]


      
export const defaultDashboard = "https://demo1.aarcomm.io/api/grafana/d/7_3_iVTVz/test-clean-harbors-dashboard-v3?orgId=1";
export const defaultDashboard2 = "https://demo1.aarcomm.io/api/grafana/d/Gta3wT-4k/test-clean-harbors-dashboard-v3-calgary?orgId=1";


const reportsDashboards = {
    "1": "https://demo1.aarcomm.io/api/grafana/d/WwGvJfaVz/test-clean-harbors-reports-calgary-v1?orgId=1", 
    "2": "https://demo1.aarcomm.io/api/grafana/d/8x3bAf-4z/test-clean-harbors-report-dashboard-v1?orgId=1",
}


export {
    rangeMS,
    pressureThreshold,
    warningThreshold,
    inventory,
    activity,
    status,
    deviceInfo,
    radioInfo,
    rules, 
    WandFields,
    SensorFields,
    Dashboards, 

    defaultTimes, 
    reportsDashboards,
    
};
