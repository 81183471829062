
class PolygonGroup {
    constructor(id, name, polygons, categories, description, createdAt, lastModify) {
        this.id = id;
        this.name = name;
        this.polygons = polygons;
        this.categories = categories;
        this.description = description;
        this.createdAt = createdAt;
        this.lastModify = lastModify;
    }

    getId() {
        return this.id;
    }

    getName () {
        return this.name;
    }

    getPolygons () {
        return this.polygons;
    }

    getCategories () {
        return this.categories;
    }

    getDescription () {
        return this.description;
    }

    getCreatedAt () {
        return this.createdAt;
    }

    getLastModify () {
        return this.lastModify;
    }

    addPolygon (polygon) {
        this.polygons.push(polygon);
    }

    modifyPolygon (newPoly) {
        let idx = this.getPolygons().findIndex((polygon) => {
            return polygon.getId() == newPoly.poly_id;
        })
        this.polygons.splice(idx, 1, newPoly);
    }

    findPolygon(id) {
        return this.getPolygons().find(polygon => {
            return polygon.poly_id == id;
        })
    }
}


class Polygon {
    constructor(id, points) {
        this.poly_id = id;
        this.points = points;
    }
    
    getId () {
        return this.poly_id;
    }

    addPoint (point) {
        this.points.push(point);
    }

    getPointsCasted () {
        let latlngs = [];
        this.points.forEach(point => {
            latlngs.push([point.getLat(), point.getLng()]);
        })
        return latlngs;
    }
}

class Point {
    constructor(id, lat, lng) {
        this.id = id;
        this.lat = lat;
        this.lng = lng;
    }

    getLat () {
        return this.lat;
    }

    getLng () {
        return this.lng;
    }

    getId () {
        return this.id;
    }
}

class CircularGeofence {
    constructor(id, name, lat, lng, radius, categories, description, createdAt, lastModify, uid, branch) {
        this.id = id;
        this.name = name;
        this.lat = Number(lat);
        this.lng = Number(lng);
        this.radius = radius;
        this.categories = categories;
        this.description = description;
        this.createdAt = createdAt;
        this.lastModify = lastModify;
        this.uid = uid;
        this.branch = branch;
    }

    getId () {
        return this.id;
    }

    getUId () {
        return this.uid;
    }

    getName () {
        return this.name;
    }
    
    getLat () {
        return this.lat;
    }

    getLng () {
        return this.lng;
    }

    getLatLngCasted() {
        return [this.lat, this.lng];
    }

    getRadius () {
        return this.radius;
    }

    getCategories () {
        return this.categories;
    }

    inOneOfCategories(categories) {
        return categories.some(cat => {
            return this.hasCategory(cat);
        })
    }

    inAllOfCategories(categories) {
        return categories.every(cat => {
            return this.hasCategory(cat);
        })
    }
    hasCategory (id) {
        return this.categories.some(cat => {
            return cat == id;
        })
    }

    getDescription () {
        return this.description;
    }

    getCreatedAt () {
        return this.createdAt;
    }

    getLastModify () {
        return this.lastModify;
    }

    getBranch () {
        return this.branch;
    }

    castAsApiBody() {
        return {
            uid: this.getUId(),
            name: this.getName(),
            type: "radius",
            coordinates: {
              center: {
                lat: this.getLat().toString(),
                lng: this.getLng().toString(),
              },
              radius: this.getRadius(),
            },
            description: this.getDescription(),
            tags_uid: this.getCategories(),
            enabled: true,
            rule: "inside",
            owner: null,
            metadata: null,
        }
    }
}


class GeofenceCategory {
    constructor(id, name, description, uid) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.uid = uid;
    }

    getId () {
        return this.id;
    }

    getName () {
        return this.name;
    }

    getDescription() {
        return this.description;
    }

    getUId () {
        return this.uid;
    }

    castAsApiBody () {
        return {
            name: this.name,
            description: this.description,
        }
    }
}
  

export {PolygonGroup, Polygon, Point, CircularGeofence, GeofenceCategory}
