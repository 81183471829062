import Vue from 'vue'
import Router from 'vue-router'
import {authGuard} from "@/middleware/authGuard";
import { permissionsCheck, isAdmin} from "../middleware/permissionCheck"
import {SYSADMIN_TAG,  ADMIN_TAG, EDITOR_TAG, USER_TAG } from '@/plugins/auth/roles';
import { manualEditType } from '@/lib/geofencing_lib/geofenceModes';


/** Main Containers And Layouts **/
const TheContainer = () => import('@/layouts/containers/TheContainer') 
import sidebarItems from "@/../config/_sidebarItems.js"; 

/** Define your Static Pages here */


/**
 * Define Default VIEWS  HERE!
 *
 * Note: We enforce Lazy Loading using a ()=> delegate to dynamically import on demand 
 * You Should have a Route to match the "View" You want to expose 
 */

 const Home = () => import("@/views/Home"); // Need a Homepage
 const Dashboard = () => import("@/views/DashboardSimple"); // Dashboard // Most apps have a dashboard 
 const Analytics = () => import("@/views/Analytics"); // 
 //const Users = ()=>import("@/views/users/Users"); // Most Apps have  a User Profile View
 const UsersList = () => import("@/views/main/users/UserListView"); // Most apps have a User List 
 const User = () => import('@/views/users/User'); 
 const Profile = ()=> import("@/views/Profile");
// We Want to use the 404 Page for routes that cannot be found 
 
 
const ReportViewer = () => import("@/views/main/reports/ReportViewer");


const Page404 = () => import('@/views/pages/Page404')
const Page401 = () => import("@/views/pages/Page401"); 


/**
 * Define Default VIEWS  HERE!
 *
 * Note: We enforce Lazy Loading using a ()=> delegate to dynamically import on demand 
 * You Should have a Route to match the "View" You want to expose 
 */



const StatusDashboard = ()=> import('@/components/dashboard/StatusDashboard'); 
const AdminView = () => import("@/views/AdminView2");
const RuleListView = ()=> import("@/views/main/rules/RuleListView"); 
const DeviceGroupListView = ()=> import("@/views/main/deviceGroups/DeviceGroupListView"); 

const ReportListView = ()=> import("@/views/main/reports/ReportListView"); 
const ReportListBranchView = ()=> import("@/views/main/reports/ReportListBranchView"); 
const ReportListTruckView = ()=> import("@/views/main/reports/ReportListTruckView");
const ReportListDayView = ()=> import("@/views/main/reports/ReportListDayView"); 

const HistoricalView = ()=>import("@/views/main/fleet/HistoricalView");

const LiveTracking = () => import("@/views/main/fleet/LiveTrackingView"); 
const RoutesView = ()=> import("@/views/main/fleet/RoutesView"); 

const JobView = ()=> import("@/views/main/jobs/JobView"); 
const JobReportView = ()=> import("@/views/main/jobs/JobReportView");

const ReportListByTruckView =() => import("@/views/main/reports/ReportListByTruckView")
const DevicesView = ()=> import("@/views/main/device/DevicesView"); 
const Maps = ()=> import("@/views/app/GoogleMaps"); 
const UserView = () => import("@/views/main/users/UserView");
const UserAddForm = () => import("@/views/main/users/UserAddForm");
const BranchViewer = ()=> import("@/views/main/branch/BranchViewer"); 
const BranchDetailView = ()=> import("@/views/main/branch/BranchDetailView");
const EditCircularGeofence = () => import ("@/components/tables/CircularGeofenceTable")
const GeofenceDetailsView = () => import ("@/views/app/geofence/GeofenceDetailsView")







Vue.use(Router)

export default new Router({
  //mode: 'hash', // https://router.vuejs.org/api/#mode
  mode: 'history',
  linkActiveClass: 'open active',
  //scrollBehavior: () => ({ y: 0 }),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
        // , offset: { x: 0, y: 10 }
      }
    }
  },
  routes: [
    {
      path: "/login",
      //redirect: "/home",
      name:"Auth",
      component: Home,
    },
    {
      path: '/', //"/"
      redirect: '/home', // "/home"
      name: 'Home',
      beforeEnter: authGuard,
      component: TheContainer,
      props: {sidebarItems: sidebarItems},
      children: [
        {
          path: 'home', // admin
          name: 'Admin',
          component: AdminView
        },
        {
          path: 'dashboard', // user
          name: 'Dashboard',
          redirect: '/fleet'
        },
        {
          path: 'fleet', // user
          name: 'Fleet',
          component: Dashboard,
          meta: { permissions: [ADMIN_TAG, USER_TAG] }, // Enable for Users and admins
        }, // Demo Only
        {
          path: 'dashboard',
          name: 'Dashboard',
          redirect: "dashboard/device",
          component: Dashboard,
          children:[
            {
              path: "device",
              name: "Device",
              component: StatusDashboard 
            },
            {
              path:"history",
              name: "History",
              component: HistoricalView
            },
            {
              path:"settings",
              name: "settings",
              component: HistoricalView
            }
          ]
        },
        {
          path: 'devices',
          name: 'Devices', 
          component: DevicesView
        },
        {
          path: 'rules',
          name: 'Rules',
          component: RuleListView
        },
        {
          path: 'deviceGroups',
          name: 'DeviceGroups',
          component: DeviceGroupListView
        },
        {
          path: 'rules/metrics',
          name: 'Rule Metrics',
          component: ()=> import("@/views/app/RulesMetrics"),
        },
        {
          path: 'routes',
          name: 'Routes',
          component: RoutesView
        },
        {
          path:'reports/truck-list',
          name: "By Truck",
          component: ReportListByTruckView,
        },
        {
          path: 'reports',
          name: 'Reports',
          redirect:"reports/list",
          // KH. CoreUI seems to require a Re-direct here 
          //component: () => import("@/views/main/DailyReportListView"),
          component: {
            render (c) { return c('router-view') }
          },
          
          //component: ReportsView,
          children:[
          {
            path:'view',
            name: "Report Viewer",
            component: ReportViewer,
          },
          {
            path:'list',
            name: "Report List",
            component: ReportListView, 
          },
          {
            path:'list/branch',
            name: "Report List Scope Branch",
            component: ReportListBranchView, 
          },
          {
            path:'list/truck',
            name: "Report List Scope Truck",
            component: ReportListTruckView, 
          },
          {
            path:'list/day',
            name: "Report List Scope Day",
            component: ReportListDayView, 
          },
          {
            path: 'printable-daily',
            name: 'Daily Printable Report',
            component: ()=> import("@/views/app/DailyReportSummaryView"),
          },
          /** Debugging Only -> View Pending Jobs */
          {
            path: 'jobs', 
            name: "Jobs View", 
            component: JobView, 
          },
          {
            path: 'report-view',
            component: JobReportView, 
          },
          {
            path:'daily-list',
            name: "Daily Report List",
            component: () => import("@/views/main/reports/DailyReportListView"),
          },
         
        ]
        },
        {
          path: 'users', // admin
          meta: { label: 'Team', permissions: [ADMIN_TAG] },
          //meta: {permissions: ["admin"], label: "Users"},
          beforeEnter: permissionsCheck,
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'User List',
              component: UsersList, // Users
            },
            {
              path: 'add/new',
              meta: {
                label: 'Add'
              },
              name: 'User',
              component: UserAddForm,
            },
            {
              path: ':id',
              meta: {
                label: 'User Details'
              },
              name: 'User',
              component: UserView,
            }
          ]
        },

        {
          path: '/branches',
          name: 'Branches',
          component: BranchViewer,
          children:[
          ]
        },

        {
          path: '/branches/:id',
          name: "View",
          component: BranchDetailView,
        },
        {
          path: "/profile",
          name: "profile", 
          component: Profile,
          beforeEnter: authGuard
        },
        {
          path: '/live',
          name: 'Live',
          component: LiveTracking,
        },

        {
          path: '/app/geofence',
          name: 'Geofence',
          component: ()=> import("@/views/app/geofence/GeofenceListView"),
        },
        {
          path: '/app/geofenceCategories',
          name: 'GeofenceCategories',
          component: ()=> import("@/views/app/geofence/GeofenceCategoriesView"),
        },          {
          path: '/app/geofence/add',
          name: manualEditType.ADD_CIRCULAR,
          component: EditCircularGeofence,
          props: true,
        },
        {
          path: '/app/geofence/:id',
          name: 'Geofence',
          component: ()=> import("@/views/app/geofence/GeofenceView"),
        },
        {
          path: '/app/geofence/:uid/details',
          name: 'GeofenceDetails',
          component: GeofenceDetailsView,
          props: true,
        },
        {
          path: '/app/geofence/:uid/edit',
          name: manualEditType.MODIFY_CIRCULAR, // h_ms_01
          component: EditCircularGeofence,
          props: true,
        },
        {
          path: '/app/maps',
          name: 'GoogleMaps',
          component: ()=> import("@/views/app/GoogleMaps"),
        },
        {
            path: '/app/branches',
            name: 'Branches',
            component: BranchViewer,
            children:[
            ]
          },
          {
            path: '/app/branches/:id',
            name: "View",
            component: ()=> import("@/views/main/branch/BranchDetailView"),
          },
        

       

        /** Put your Demo Routes here, Used for Staging and A/B Testing*/
        {
          path: "/demo", 
          component: {
            render(c) { return c('router-view') }
          },
          children: [

            {
              path: 'devices',
              name: 'DeviceView',
              component: ()=> import("@/views/main/device/DevicesView"),
            },
            {
              path: 'devices/:id/manage',
              name: 'Manage Device',
              component: ()=> import("@/views/main/device/DeviceManagementView"),
            },
            {
              path: '/demo/dailyreports',
              name: 'Daily Report',
              component: ()=> import("@/views/app/DailyReportSummaryView"),
            },
            {
              path:'/demo/truck-status', 
              name: "Status",
              component:()=>import('@/views/app/TruckStatusControl'),  
            }
          ]
        }, 
        


        /** Put your Development Routes Here  */
        {
          
          path: "/development",
          name: "Development",
          beforeEnter: permissionsCheck, 
          meta: [], 

          component: {
            render(c) { return c('router-view') }
          },
          children: [


          ]
        }
        
        
       
      ],

    },


    {
      path: "/unauthorized", 
      component:Page401, 
    },
    
    {
      // will match everything
      path: '*',
      component: Page404
    }
  ]
})
